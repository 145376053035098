// 空间推荐
export default [
  {
    path: "/explore",
    name: "explore",
    meta: {
      title: "空间推荐",
    },
    component: () => import("@/views/explore/index.vue"),
  },
];
