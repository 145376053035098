// 评测用例
export default [
  {
    path: "/cluster",
    name: "cluster",
    meta: {
      title: "评测用例",
      activeMenu: "/cluster",
      requiresAuth: true
    },
    component: () => import("@/views/evaluatingCase/index.vue"),
    redirect: "/cluster/index",
    children: [
      {
        path: "/",
        meta: {
          title: "评测用例",
        },
        component: () => import("@/views/evaluatingCase/case"),
        name: "caseList",
      },
      {
        path: ":id",
        meta: {
          title: "用例详情",
          activeMenu: "/cluster",
        },
        component: () => import("@/views/evaluatingCase/caseDetail"),
        name: "caseDetail",
      },
    ],
  },
];
