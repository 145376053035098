// 竞赛
export default [
    {
        path: "/MSR",
        name: "MSR",
        meta: {
            title: "评测竞赛",
        },
        component: () => import("@/views/competition/MSR.vue"),
    },
    {
        path: "/ICPR",
        meta: {
          title: "ICPR2022竞赛",
        },
        component: () => import("@/views/competition/ICPR.vue"),
        name: "ICPR",
    },
    {
      path: "/VMR",
      meta: {
        title: "长短视频多语种多模态识别竞赛_数据竞赛与活动_数据堂",
      },
      component: () => import("@/views/competition/VMR.vue"),
      name: "VMR",
    },
    {
      path: "/INTERSPEECH",
      meta: {
        title: "口音英语语音识别技术研讨会暨挑战赛-数据堂",
      },
      component: () => import("@/views/competition/INTERSPEECH.vue"),
      name: "INTERSPEECH",
    },
    {
        path: "/opensource",
        meta: {
          title: "1505小时中文普通话语音数据",
        },
        component: () => import("@/views/competition/opensource.vue"),
        name: "opensource",
      }
];
