<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\views\agreement\index.vue
 * @Date: 2022-10-13 18:20:47
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->

<template>
  <div>
    <div>
      我已阅读并同意《<span class="blue" @click="toAgreement(2)">服务协议</span> 》《<span class="blue" @click="toAgreement(1)">隐私协议</span>》
    </div>

    <el-dialog
      title="提示"
      :visible.sync="visible"
      width="30%"
      append-to-body
    >
      <span>这是一段信息</span>
      <span slot="footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="visible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    toAgreement(type) {
      window.open(`/agreementContent/${type}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.blue {
  color: #337dff;
  cursor: pointer;
  &:hover{
    color:#0D53CE;
  }
}
</style>