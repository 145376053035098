/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\more.js
 * @Date: 2022-07-07 15:48:20
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 更多
export default [
  {
    path: "/knowledge",
    name: "knowledge",
    meta: {
      title: "评测知识",
      requiresAuth: true
    },
    component: () => import("@/views/more/knowledge/index.vue"),
    children: [],
  },
  {
    path: "/knowledge/standards/:id",
    name: "standards",
    meta: {
      title: "standards",
      requiresAuth: true
    },
    component: () => import("@/views/more/knowledge/moredetails/detailslist.vue"),
  },
  {
    path: "/knowledge/cases/:id",
    name: "cases",
    meta: {
      title: "cases",
      requiresAuth: true
    },
    component: () => import("@/views/more/knowledge/moredetails/cases.vue"),
  },
  {
    path: "caselist/:id",
    name: "caselist",
    meta: {
      title: "caselist",
      requiresAuth: true
    },
    component: () => import("@/views/more/knowledge/moredetails/caselist.vue"),
  },
  {
    path: "/news",
    name: "newslist",
    meta: {
      title: "评测新闻",  
      requiresAuth: true
    },
    component: () => import("@/views/more/Journalism/index.vue"),
  },
  {
    path: "news/:id",
    name: "news",
    meta: {
      title: "news",
      requiresAuth: true
    },
    component: () => import("@/views/more/Journalism/newspage/evaluating.vue"),
  },
];
