//移动端设备路由
import more from "@/router/module/more";
import settings from "@/router/module/settings";
import space from "@/router/module/space";
import portal from "@/router/module/portal";
import evaluatingCase from "@/router/module/evaluatingCase";
import login from "@/router/module/login";

const Layout = () => import("@/layout/index.vue");
const LayoutMobile = () => import("@/layout/mobile.vue");
export default [
    {
        path: "/404",
        component: () => import("@/views/error-page/404"),
        hidden: true,
    },
    ...login,
    {
        path: "/",
        component: LayoutMobile,
        children: [
            {
                path: "/",
                //component: () => import("@/views/mobile/home/index.vue"),
                component: () => import("@/views/mobile/home/home.vue"),
                name: "home",
                meta: {
                    title: "门户",
                }
            },
            {
                path: "/direction",
                meta: {
                    title: "评测领域",
                    activeMenu: "/direction",
                    requiresAuth: true
                },
                component: () => import("@/views/mobile/direction/index.vue"),
                redirect: "/direction",
                children: [
                    {
                        path: "/",
                        meta: {
                            title: "评测领域",
                        },
                        component: () => import("@/views/mobile/direction/directionField"),
                        name: "direction",
                    },
                    {
                        path: ":field",
                        meta: {
                            title: "计算机视觉",
                            activeMenu: "/direction",
                        },
                        component: () => import("@/views/mobile/direction/directionList"),
                        name: "field",
                    },
                    {
                        path: ":field/:id",
                        meta: {
                            title: "目标检测",
                            activeMenu: "/direction",
                        },
                        component: () => import("@/views/mobile/direction/directionSecondList"),
                        name: "list",
                    },
                ],
            },
            {
                path: "/cluster",
                component: () => import("@/views/mobile/cluster/index.vue"),
                name: "clusterM",
                meta: {
                    title: "评测用例",
                }
            },
            {
                path: "/explore",
                component: () => import("@/views/mobile/explore/index.vue"),
                name: "explore",
                meta: {
                    title: "空间推荐",
                }
            },
            {
                path: "/news",
                component: () => import("@/views/mobile/news/index.vue"),
                name: "newsM",
                meta: {
                    title: "评测新闻",
                }
            },
            {
                path: "/standard",
                component: () => import("@/views/mobile/standard/index.vue"),
                name: "standard",
                meta: {
                    title: "评测标准",
                }
            },
            {
                path: "/case",
                component: () => import("@/views/mobile/case/index.vue"),
                name: "case",
                meta: {
                    title: "评测案例",
                }
            },

        ]
    },
    {
        path: "/",
        component: Layout,
        // 这里开始对应的路由都会显示在router-view中 如上图所示
        children: [
            ...portal,
            ...evaluatingCase,
            ...more,
            ...settings,
            ...space,
        ],
    },
    { path: "*", redirect: "/404", hidden: true },
]