<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \release\evaluation-protal\src\components\dt\Table\table.vue
 * @Date: 2022-08-02 14:12:16
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->

<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div>
        <span v-if="isShowCheckedNum" style="display: inline-block; padding: 15px 0;">
          已选 {{ checkedNum }} 条,
          共 {{ data.length }} 条
        </span>
      </div>
      <div>
        <div v-if="$slots.extra" style="display: inline-block; padding: 15px 0;">
          <slot name="extra" />
        </div>
      </div>
    </div>
    <el-table
      class="custom-table"
      v-loading="loading"
      :element-loading-text="loadingText"
      :element-loading-spinner="loadingSpinner"
      :element-loading-background="loadingBackground"
      :data="data"
      :style="`width: ${width}`"
      :border="border"
      :stripe="stripe"
      :size="size"
      :height="height"
      :header-cell-style="headerCellStyle"
      :row-style="rowStyle"
      @selection-change="selectionChange"
      @select="select"
      @sort-change="sortChange"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
    >
      <el-table-column
        v-if="selectType === 'checkbox'"
        type="selection"
        width="50"
      />
      <el-table-column
        v-if="selectType === 'radio'"
        width="50"
        :label="radioLabel"
      >
        <template slot-scope="scope">
          <el-radio v-model="radioIndex" :label='scope.$index' @change="onRadio(scope.row)"><div></div></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in column"
        :key="col.prop"
        :label="col.label"
        :width="col.width"
        :min-width="col.minWidth"
        :align="col.align || 'left'"
        :fixed="col.fixed"
        :sortable="col.sortable"
        :sort-method="col.sortMethod"
        :sort-orders="col.sortOrders"
        :show-overflow-tooltip="col.showOverflowTooltip"
      >
        <template slot-scope="{row, $index}">
          <slot v-if="col.slotName" :name="col.slotName" :row="row"/>
          <div
            v-else
            :style="col.style"
            :class="{'ellipsis': col.ellipsis}"
            @click="action(col.action, row)"
          >
            {{ (col.formatter ? col.formatter(row, col, $index) : row[col.prop]) || col.empty || '--' }}
          </div>
        </template>
      </el-table-column>
      <template slot="empty" v-if="!isEmpty">
        <div class="empty-table" v-if="emptyState">
          <img src="@/assets/emptyLst.png" alt="" style="height: 336px" />
          <p style="top: 70%; transform: translate(-50%, -70%)">暂无数据</p>
        </div>
        <div class="empty-table" v-else>
          <img src="@/assets/emptyLstGray.png" alt="" style="height: 223px" />
          <p style="top: 85%; transform: translate(-50%, -85%)">暂无数据</p>
        </div>
      </template>
    </el-table>
    <div style="padding: 24px 0 4px; text-align: right;">
      <slot name="pagination" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DtTable1',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    column: {
      type: Array,
      default: () => {
        return []
      }
    },
    isEmpty: {
      default() {
        return false; //不显示暂无数据
      },
      type: Boolean,
    },
    emptyState: {
      default() {
        return true; //因为表格有的是白色底有的是灰色底
      },
      type: Boolean,
    },
    border: {
      type: Boolean,
      default: true
    },
    stripe: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    selectType: {
      type: String,
      default: ''
    },
    radioLabel: {
      type: String,
      default: '单选'
    },
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          background: '#F5F6FA',
          border: 'none',
        }
      }
    },
    rowStyle: {
      type: Function,
      default: ({ row, rowIndex }) => {
        return {}
      }
    },
    isShowCheckedNum: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: '加载中，请稍后...'
    },
    loadingSpinner: {
      type: String,
      default: 'el-icon-loading'
    },
    loadingBackground: {
      type: String,
      default: 'rgba(255, 255, 255, .5)'
    }
  },
  data() {
    return {
      checkedNum: 0,
      radioIndex: ''
    }
  },
  methods: {
    selectionChange(rows) {
      this.checkedNum = rows.length
      this.$emit('selectionChange', rows)
    },
    select(selection, row) {
      this.$emit("select", selection, row);
    },
    sortChange(column) {
      this.$emit("sortChange", column);
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit("cell-mouse-enter", { row, column, cell, event });
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit("cell-mouse-leave", { row, column, cell, event });
    },
    onRadio(row) {
      this.$emit('onRadio', row)
    },
    action(cb, row) {
      if (typeof cb === 'function') {
        cb(row)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/index.scss";

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.empty-table {
  text-align: center;
  color: #abb1bf;
  font-size: 16px;
  padding-top: 50px;
  position: relative;
  img {
    width: imgZoomWidth(756) + px;
  }
  p {
    position: absolute;
    left: 50%;
  }
}

</style>

<style lang="scss">
.custom-table {
  thead {
    th:nth-of-type(1) {
      .cell {
        padding-left: 24px !important;
      }
    }
  }
  tbody {
    tr {
      td:nth-of-type(1) {
        .cell {
          padding-left: 24px !important;
        }
      }
    }
  }
  .el-table__body-wrapper{
    height: 100% !important;
  }
}
</style>
