import axios from "axios";
import { Message, Loading, MessageBox } from "element-ui";
import store from "@/store";
import { getToken, removeToken } from "@/utils/auth";
import login from "@/views/login/login";
import router from "@/router";
let refrshDis = true;
const whiteList = [
  "/",
  "/login",
  "/direction",
  "/cluster",
  "/explore",
  "/MSR",
  "/VMR",
  "/ICPR",
  "/INTERSPEECH",
  "/opensource",
  "/Journalism",
  "/news",
  "/standard",
  "/case",
  "/knowledge/standards",
  "/knowledge",
  "/user-manual",
  "/api-docs"
]; // no redirect whitelist
// const whiteList = []; // no redirect whitelist

const whiteNameList = ["contribution", "account", "news", "standards", "cases", "agreementContent", "IssueDetails","privacyPolicy","serverPolicy"];
const request = (options) => {
  var config = {
    baseURL: options.baseURL ? options.baseURL : process.env.VUE_APP_BASE_API,
    timeout: options.timeout ? options.timeout : 600000,
    // 默认为true
    loading: options.loading === void 0 ? true : options.loading,
    isStatic: options.isStatic === void 0 ? false : options.isStatic,
  };
  let loading = null;
  if (options.responseType) {
    config.responseType = options.responseType;
  }
  if (options.isPro) {
    config.isPro = options.isPro;
  }
  const service = axios.create(config);
  service.interceptors.request.use(
    (config) => {
      if (config.loading) {
        loading = Loading.service({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
      }
      if (config.responseType) {
        config.headers["Content-Type"] =
          "application/json; application/octet-stream";
      }
      config.headers.appId = "871E670E61534BAA9AE41C65BB7DC1E7";
      var infos = getToken();
      if (infos) {
        var ti = infos.ti;
        var gap = parseInt(Date.now()) - parseInt(ti);
        if (
          gap >= 12 * 60 * 60 * 1000 &&
          gap <= 24 * 60 * 60 * 1000 &&
          refrshDis
        ) {
          refrshDis = false;
          store.dispatch("user/refresh").then(() => {
            refrshDis = true;
          });
        }
        config.headers.token = infos.token;
      }
      if (config.isPro) {
        config.headers["evaluation-portal-token"] = config.headers.token;
        config.headers["evaluation-portal-orgCode"] = store.getters.account;
      }
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );

  // response interceptor
  service.interceptors.response.use(
    (response) => {
      try {
        if (response.config.loading) {
          loading && loading.close();
        }
      } catch (error) {
        console.log(error);
      }
      const res = response.data;
      // if the custom status is not 200, it is judged as an error.
      // 203异常属于pro提示在peo没有数据，不需要错误提示
      if (res.status !== 200 && res.status !== 203) {
        if (config.isStatic || config.responseType) {
          return response;
        }

        Message.closeAll();
        if (res.status === "401" || res.status === 401) {
          // 登录异常后清除当前token
          if (
            !(whiteList.indexOf(router.history.current.path) !== -1 ||
            whiteNameList.indexOf(router.history.current.name)!== -1 )&&!store.getters.loginPop
          ) {
            store.dispatch("user/setLoginPop", true);
            // 弹出登录弹窗
            login.install({ category: "1" });
          }
          store.dispatch('user/resetToken')
        } else if (res.status === 500 || res.status === 404) {
          Message({
            message: res.msg || "Error",
            type: "error",
            duration: 2 * 1000,
          });
        } else if (res.status === 5005018 || res.status === 5005036 || res.status === 5005019) {
          if (!window.messageErrTip) {
            window.messageErrTip = MessageBox.confirm(res.msg, "提示", {
              confirmButtonText: "确认",
              showCancelButton: false,
              showClose: false,
              customClass: "baseAlert warning",
            })
              .then((data) => {
                router.replace({
                  path: "/direction",
                });
                window.messageErrTip = null;
              })
              .catch((err) => {
                window.messageErrTip = null;
              });
          }
        } else if (res.status === 5012009) {
          if (!window.messageErrTip) {
            window.messageErrTip = MessageBox.confirm(res.msg, "提示", {
              confirmButtonText: "确认",
              showCancelButton: false,
              showClose: false,
              customClass: "baseAlert warning",
            })
              .then((data) => {
                location.reload();
                window.messageErrTip = null;
              })
              .catch((err) => {
                window.messageErrTip = null;
              });
          }
        } else {
          Message({
            message: res.msg || "Error",
            type: "warning",
            duration: 2 * 1000,
          });
        }
        return res;
      } else {
        return res;
      }
    },
    (error) => {
      try {
        loading && loading.close();
      } catch (error) {
        console.log(error);
      }
      Message.closeAll();
      Message({
        message: error.message,
        type: "error",
        duration: 2 * 1000,
      });
      return Promise.reject(error);
    }
  );
  return {
    get({ url, data }) {
      return service({
        url: url,
        method: "get",
        params: data,
      });
    },
    post({ url, data }) {
      return service({
        url: url,
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: JSON.stringify(data),
      });
    },
    put({ url, data }) {
      return service({
        url: url,
        method: "put",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: data,
      });
    },
    delete({ url, data }) {
      return service({
        url: url,
        method: "delete",
        params: data,
      });
    },
    service,
  };
};

export default request;
