<template>
  <div>
    <div id="login_container" class="qrcode"></div>
    <span @click="handleCut('1')">手机快捷登录</span>
  </div>
</template>

<script>
export default {
  name: "DdLogin",
  data() {
    return {}
  },
  mounted() {
    this.ddLoginInit()
  },
  methods: {
    ddLoginInit(){
      //钉钉扫码流程：扫码成功登录后会自动跳到这个url页面，url路径会携带code，你拿到这个code，调用登录接口成功就跳转。
      //你的项目页面
      let path = window.g.DingRedirectUri
      let url = encodeURIComponent(path);
      // appid 找后端要
      let appid = window.g.DingAppid
      let dingState = window.g.DingState
      // 钉钉自己的url 修改上面俩，不需要动这个
      let goto = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=${dingState}&redirect_uri=${url}`)
      let obj = DDLogin({
        id:"login_container",//这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
        goto: goto, //请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width : "100%",//官方参数 365
        height: "400"//官方参数 400
      });
      let handleMessage =  (event) =>{
        let origin = event.origin;
        if( origin === "https://login.dingtalk.com" ) { //判断是否来自ddLogin扫码事件。
          let loginTmpCode = event.data;
          //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          //此步拿到临时loginTmpCode换取正式code
          window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=${dingState}&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`
        }
      };
      if (typeof window.addEventListener != 'undefined') {
        window.addEventListener('message', handleMessage, false);
      } else if (typeof window.attachEvent != 'undefined') {
        window.attachEvent('onmessage', handleMessage);
      }
    },
    // 切换页面
    handleCut(data) {
      this.$emit("handleCut", data)
      this.$emit('telLogin')
    },
  }
}
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  padding-top: 70px;
}
span {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-weight: 500;
  color: #337dff;
  font-size: 14px;
  cursor: pointer;
  &:hover{
    color:#0D53CE;
  }
}
</style>