/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\store\modules\upload.js
 * @Date: 2022-08-11 15:21:23
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
const state = {
  // 控制上传状态是否是上传中
  uploadStatus: false,
  cancelStatus: false, // 禁止重複調用修改批次狀態接口
  // 主要是针对模型开发上传
  fileStatus:"",// 0-上传中; 1-上传成功; 2-上传失败
  uploadId:"",//实则为任务id
  uploadCompleteData:[],//上传完成的数据
};
const mutations = {
  SWITCHUPLOAD: (state) => {
    state.uploadStatus = true;
  },
  RESETUPLOAD: (state) => {
    state.uploadStatus = false;
  },
  SETCANCEL: (state) => {
    state.cancelStatus = true;
  },
  REMOVECANCEL: (state) => {
    state.cancelStatus = false;
  },
  UPDATESTATUS:(state)=>{
    state.fileStatus = state
  },
  SETTASKID:(uploadId)=>{
    state.uploadId = uploadId
  },
  GETCOMPLETEDATA:(data)=>{
    state.uploadCompleteData=state.uploadCompleteData.concat(data)
  }
};

const actions = {
  switchUpload({ commit }) {
    commit("SWITCHUPLOAD");
  },
  resetUpload({ commit }) {
    commit("RESETUPLOAD");
  },
  setCancel({ commit }) {
    commit("SETCANCEL");
  },
  removeCancel({ commit }) {
    commit("REMOVECANCEL");
  },
  updateStatus({commit},data){
    commit('UPDATESTATUS',data)
  },
  setTaskId({commit},data){
    commit('SETTASKID',data)
  },
  getCompleteData({commit},data){
    commit('GETCOMPLETEDATA',data)
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
