<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/components/dt/TitleBar/index.vue
 * @Date: 2022-07-18 15:32:37
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="title-bar_container">
    <h3><slot /></h3>
  </div>
</template>

<script>
export default {
  name: "DtTitle",
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.title-bar_container {
  line-height: 28px;
  h3 {
    font-size: 28px;
  }
  // font-weight: 500;
}
</style>
