<!--
 * @Author: xuqiang xuqiang@datatang.com
 * @Date: 2022-07-25 11:19:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-10-14 18:37:37
 * @FilePath: \evaluation-protal\src\components\dt\Breadcrumb\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  面包屑
  key: 1,
  name: "菜单名称",
  to: { path: "" ,query:''}, 跳转路径及参数
  replace: false,
  isCurrent: false 代表当前菜单
 -->
<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="item in datas" :key="item.key" :replace="item.replace">
        <span @click="jumpHref(item)">{{ item.name }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "DtBreadcrumb",
  props: {
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      levelList: null,
    };
  },
  created() { },
  methods: {
    jumpHref(item) {
      console.log(item);
      if (!item.isCurrent) {
        if (item.isCustom) {
          this.$emit('handlerCustom', item.isCustom)
        } else {
          this.$router.push({
            name: item.to.name,
            path: item.to.path,
            query: item.to.query,
            params: item.to.params,
          });
        }

        window.event && (window.event.returnValue = false);
      }
    },
    handleLink(item, index) {
      if (index === this.datas.length - 1) return;
      this.$router.push({ name: item.routerName, query: item.params });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: block;
  font-size: 14px;
  margin: 15px 0;
  span {
    cursor: pointer;
    color: #337dff;
    line-height: 14px;
    &:hover {
      color: #6c96ff;
    }
  }
  ::v-deep[aria-current="page"] span {
    color: #333;
  }
  ::v-deep .el-breadcrumb__item:nth-last-child(1) {
    span {
      color: #333;
    }
  }
}
</style>
