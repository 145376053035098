/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\utils\auth.js
 * @Date: 2022-07-08 15:38:46
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// import Cookies from "js-cookie";

const TokenKey = "token";

export function getToken() {
  var infos = localStorage.getItem(TokenKey);
  try {
    infos = infos && JSON.parse(infos);
  } catch (e) {
    console.log(e);
    infos = null;
  }
  return infos;
}

export function setToken(info) {
  return localStorage.setItem(TokenKey, JSON.stringify(info));
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
export function generateTokenInfo(token) {
  var info = { ti: Date.now(), token: token };
  return info;
}
