<template>
  <div class="findPassContainer">
    <div class="category">
      <ul>
        <li>找回密码</li>
      </ul>
    </div>

    <el-form
      ref="findPassForm"
      :model="findPassObj"
      :rules="findPassRules"
      class="formBox findPassForm"
    >
      <!-- 手机号 -->
      <el-form-item prop="keyword">
        <el-input
          v-model="findPassObj.keyword"
          @change="deleteSpace(findPassObj.keyword,'keyword')"
          placeholder="请输入手机号/邮箱"
          maxlength="30"
          type="text"
        />
      </el-form-item>
      <el-form-item prop="smsCode" class="shortFrame">
        <NoteVaild
          :isUsable="isCompanyName && isExist"
          :number="findPassObj.keyword"
          :mode="mode"
          @handleNote="handleNote"
        ></NoteVaild>
        <el-input
          v-show="false"
          v-model="findPassObj.smsCode"
          placeholder="请输入验证码"
          type="text"
          @change="deleteSpace(findPassObj.smsCode,'smsCode')"
        />
      </el-form-item>
      <el-form-item prop="pass">
        <el-input v-model="findPassObj.pass" placeholder="请输入新密码" type="password" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="findPassObj.password" placeholder="请再次确认密码" type="password" />
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="default"
        style="width:100%;margin-bottom:15px"
        @click.native.prevent="handleVerify"
      >确认</el-button>
      <p class="goLogin" @click="handleCutPage">返回登录</p>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import { forgetPass, userConcat, checkTel, checkEmailExist } from "@/api/user";
import md5 from "js-md5";
import lodash from "lodash";
import { regExpObj } from "@/utils/validate";

export default {
  name: "FindPass",
  components: { NoteVaild },
  data() {
    return {
      lodash,
      findPassObj: {
        keyword: "",
        smsCode: "",
        pass: "",
        password: "",
      },
      mode: "FORGETPASS",
      isCompanyName: false, // 号码格式校验是否通过
      isExist: false,
    }
  },
  computed: {
    findPassRules() {

      // 注册  用户密码第一次输入   
      const firstPassWorld = (rule, value, callback) => {
        const rq = regExpObj.passWordExp
        if (value === "") {
          callback(new Error("请输入密码"));
        } else if (!rq.test(value)) {
          callback(new Error("请输入6-18位密码，必须包含字母和数字(区分大小写)"));
        } else {
          if (this.findPassObj.password !== "") {
            this.$refs.findPassForm.validateField("password");
          }
          callback();
        }
      };
      // 注册 用户密码第二次输入
      const secPassWorld = (rule, value, callback) => {
        const rq = regExpObj.passWordExp
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.findPassObj.pass) {
          callback(new Error("两次输入密码不一致!"));
        } else if (!rq.test(value)) {
          callback(new Error("请输入6-18位密码，必须包含字母和数字(区分大小写)"));
        } else {
          callback();
        }
      };
      // 手机号校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.emailExp;
        const rq = regExpObj.phoneExp;
        if (value === "") {
          callback(new Error("手机号或者邮箱需要至少输入一项"));
        } else if (value.indexOf("@") >= 0) {
          if (!re.test(value)) {
            callback(new Error("邮箱格式不正确"));
          } else {
            this.isCompanyName = true;
            callback();
          }
        } else {
          if (!rq.test(value)) {
            callback(new Error("手机格式不正确"));
          } else {
            this.isCompanyName = true;
            callback();
          }
        }
      };
      return {
        keyword: [{
          required: true,
          validator: phoneRule,
          trigger: "blur"
        }],
        smsCode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
        pass: [
          { required: true, trigger: "blur", validator: firstPassWorld }
        ],
        password: [
          { required: true, trigger: "blur", validator: secPassWorld }
        ],
      };
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    // 跳转登录页面
    handleCutPage() {
      this.$emit("handleCut", { type: '1' })
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      this.findPassObj[keyword] = value.trim();

      if (keyword === 'keyword') {
        this.checkExist(value)
      }
    },
    // 保存短信验证码值
    handleNote(data) {
      this.findPassObj.smsCode = data
    },
    // 校验输入的电话号码或邮箱是否已注册
    checkExist(value) {
      const val = value.trim();
      if (!val) return
      
      if (val.includes('@')) {
        const re = regExpObj.emailExp;
        if (!re.test(val)) return 
        
        checkEmailExist({email: val}).then(res => {
          const { data, status } = res
          const { exist } = data || {}
          if (status === 200) {
            if (exist === false) {
              this.isExist = true;
            } else {
              this.isExist = false;
              this.$message.warning(`${val}未注册`)
            }
          }
        })
      } else {
        const rq = regExpObj.phoneExp;
        if (!rq.test(val)) return
        
        checkTel({telephone: val}).then(res => {
          const { data, status } = res
          const { exist } = data || {}
          if (status === 200) {
            if (exist === false) {
              this.isExist = true;
            } else {
              this.isExist = false;
              this.$message.warning(`${val}未注册`)
            }
          }
        })
      }
    },
    // 绑定校验
    handleVerify() {
      this.$refs.findPassForm.validate(valid => {
        if (valid) {
          this.handleRegist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改密码
    async handleRegist() {
      const type = this.findPassObj.keyword.indexOf("@") >= 0 ? 'EMAIL' : "TELEPHONE";
      const res = await forgetPass({
        smsCode: this.findPassObj.smsCode,
        password: md5(this.findPassObj.password),
        mode: this.mode,
        keyword: this.findPassObj.keyword,
        type: type
      })
      if (res.status === 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.handleCut()
        // this.$store.dispatch("user/setLoginPop", false);
      }
    },
    // 修改成功跳转成功提示页面
    handleCut() {
      this.$emit("handleCut", { type: '4', status: '3' })
    },
  }
};
</script>
<style lang="scss" scoped>
.findPassContainer {
  padding: 35px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }

    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
        .el-input {
          display: inline-block;
          width: 100%;

          input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 0 10px;
            color: #333;
            caret-color: #999;

            &:-webkit-autofill {
              box-shadow: 0 0 0px 1000px #fff inset !important;
              -webkit-text-fill-color: #333 !important;
            }
          }
        }
      }
      // &:last-child {
      //   margin-bottom: 10px;
      // }
      &.shortFrame {
        border: 0;
        .el-input {
          border: 1px solid #ddd;
          width: 200px;
        }
      }
    }
  }
  .goLogin {
    text-align: right;
    float: right;
    // span {
    color: #337dff;
    cursor: pointer;
    // }
    &:hover{
      color:#0D53CE;
    }
  }
}
</style>

