/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\views\login\login.js
 * @Date: 2022-07-19 17:56:17
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
import Vue from "vue";
// 引入组件
import Login from "@/views/login/index.vue";
import store from "@/store";
// 创建构造器
const LoginBox = Vue.extend(Login);

Login.install = function (data) {
  // data：调用组件时传入参数【操作组件data中数据】，见下图
  // console.log("data====>", data);

  // 创建组件对象，并挂载
  let instance = new LoginBox({
    store,
    data,
  }).$mount(); // $mount(document.createElement('div') 【也可以挂载到某个元素上】
  // console.log(instance);
  // 将组件添加到dom中
  document.body.appendChild(instance.$el);

  instance.remove = () => {
    // 移除本身
    document.body.removeChild(instance.$el);
    // 释放自己所占资源
    instance.$destroy();
  };
};

export default Login;
