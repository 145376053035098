<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\views\login\components\successPage.vue
 * @Date: 2022-07-18 14:13:40
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="successContainer">
    <svg-icon icon-class="success"></svg-icon>
    <p>{{text}}</p>
    <el-button type="primary" @click="handleCut">{{btnText}}</el-button>
  </div>
</template>
<script>
export default {
  name: "SuccessPage",
  data() {
    return {
      protocol: false,
      text: "",
      btnText: ""
    }
  },
  props: {
    status: {
      type: String,
      default() {
        return "2";
      }
    }
  },
  watch: {
    status: {
      handler(newName) {
        if (newName === '2') {
          this.text = "恭喜！您已注册成功！"
          this.btnText = "立即登录"
        }
        if (newName === '3') {
          this.text = "找回密码成功，请牢记新的登录密码"
          this.btnText = "点此登录"
        }
        if (newName === '7') {
          this.text = "修改密码成功，请牢记新的登录密码 "
          this.btnText = "点击回到页面"
        }
      },
      immediate: true,
      deep: true
    },

  },
  created() {
  },
  methods: {
    handleCut() {
      if(this.status === '7'){
        window.location.reload()
      }else{
        this.$emit("handleCut", '1')
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.successContainer {
  padding: 137px 30px;
  text-align: center;
  svg {
    font-size: 50px;
  }
  p {
    padding: 27px 0 29px;
    font-size: 18px;
    color: #111111;
  }
}
</style>
