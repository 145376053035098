<template>
  <div class="findPassContainer">
    <div class="category">
      <ul>
        <li>修改密码</li>
      </ul>
    </div>

    <el-form
      ref="findPassForm"
      :model="findPassObj"
      :rules="findPassRules"
      class="formBox findPassForm"
    >
      <!-- 手机号 -->
      <el-form-item prop="loginName">
        <el-select v-model="findPassObj.type" placeholder="请选择认证方式" style="width:308px">
          <el-option
            v-for="item in userConcat"
            :key="item.type"
            :label="item.number"
            :value="item.type"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="smsCode" class="shortFrame">
        <NoteVaild :type="findPassObj.type" :isUsable="true" :mode="mode" @handleNote="handleNote"></NoteVaild>
        <el-input
          v-show="false"
          v-model="findPassObj.smsCode"
          placeholder="请输入验证码"
          type="text"
          @change="deleteSpace(findPassObj.smsCode,'smsCode')"
        />
      </el-form-item>
      <el-form-item prop="pass">
        <el-input v-model="findPassObj.pass" placeholder="请输入新密码" type="password" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="findPassObj.password" placeholder="请再次确认密码" type="password" />
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="default"
        style="width:100%;margin-bottom:10px"
        @click.native.prevent="handleVerify"
      >确认</el-button>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import { amendPass, userConcat } from "@/api/user";
import md5 from "js-md5";
import { regExpObj } from "@/utils/validate";

export default {
  name: "AmendPass",
  components: { NoteVaild },
  data() {
    return {
      findPassObj: {
        type: "",
        smsCode: "",
        pass: "",
        password: "",
      },
      userConcat: [], //当前用户已绑定的手机和邮箱
      mode: "PASSWORD",
    }
  },
  computed: {
    findPassRules() {

      // 注册  用户密码第一次输入   
      const firstPassWorld = (rule, value, callback) => {
        const rq = regExpObj.passWordExp
        if (value === "") {
          callback(new Error("请输入密码"));
        } else if (!rq.test(value)) {
          callback(new Error("请输入6-18位密码，必须包含字母和数字(区分大小写)"));
        } else {
          if (this.findPassObj.password !== "") {
            this.$refs.findPassForm.validateField("password");
          }
          callback();
        }
      };
      // 注册 用户密码第二次输入
      const secPassWorld = (rule, value, callback) => {
        const rq = regExpObj.passWordExp
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.findPassObj.pass) {
          callback(new Error("两次输入密码不一致!"));
        } else if (!rq.test(value)) {
          callback(new Error("请输入6-18位密码，必须包含字母和数字(区分大小写)"));
        } else {
          callback();
        }
      };
      return {
        smsCode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
        pass: [
          { required: true, trigger: "blur", validator: firstPassWorld }
        ],
        password: [
          { required: true, trigger: "blur", validator: secPassWorld }
        ],
      };
    }
  },
  watch: {
  },
  created() {
    this.getUserConcat();
  },
  methods: {
    // 加密回显当前用户已绑定的手机号及邮箱
    async getUserConcat() {
      this.userConcat = [];
      const res = await userConcat();
      if (res.status === 200) {
        if (res.data.telephone) {
          this.userConcat.push({ number: res.data.telephone, type: '1' });
          this.findPassObj.type = '1';
        }
        if (res.data.email) {
          this.userConcat.push({ number: res.data.email, type: '2' });
          this.findPassObj.type = '2';
        }
      }
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {

      this.findPassObj[keyword] = value.trim();
    },
    // 保存短信验证码值
    handleNote(data) {
      this.findPassObj.smsCode = data
    },
    // 绑定
    handleVerify() {
      this.$refs.findPassForm.validate(valid => {
        if (valid) {
          this.handleRegist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleRegist() {
      const type = this.findPassObj.type === '1' ? 'TELEPHONE' : "EMAIL";
      const res = await amendPass({
        smsCode: this.findPassObj.smsCode, password: md5(this.findPassObj.password), mode: this.mode, type: type
      })
      if (res.status === 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.handleCut()
        // this.$store.dispatch("user/setLoginPop", false);
      } else {
        res.status === 401 && this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },
    handleCut() {
      this.$emit("handleCut", { type: '4', status: '7' })
    },
  }
};
</script>
<style lang="scss" scoped>
.findPassContainer {
  padding: 35px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }

    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
        .el-input {
          display: inline-block;
          width: 100%;

          input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 0 10px;
            color: #333;
            caret-color: #999;

            &:-webkit-autofill {
              box-shadow: 0 0 0px 1000px #fff inset !important;
              -webkit-text-fill-color: #333 !important;
            }
          }
        }
      }
      // &:last-child {
      //   margin-bottom: 10px;
      // }
      &.shortFrame {
        border: 0;
        .el-input {
          border: 1px solid #ddd;
          width: 200px;
        }
      }
    }
  }
}
</style>
