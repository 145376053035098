/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\utils\common.js
 * @Date: 2022-08-22 18:30:43
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
export function handleTitle(data) {
  let belongText = "";
  switch (data) {
    case "3,4":
      belongText = "样例数据";
      break;
    case "1":
      belongText = "评测用例原文件";
      break;
    case "2":
      belongText = "评测用例结果文件";
      break;
    default:
      belongText = "-";
      break;
  }
  return belongText;
}
// 转换时间
export function timeZhuan(s) {
  var m = 0;
  var h = 0;
  var result = "";
  if (s > 60) {
    // 是否大于零
    m =
      Math.round(s / 60) >= 10 ? Math.round(s / 60) : "0" + Math.round(s / 60); // 分钟
    s =
      Math.round(s % 60) >= 10 ? Math.round(s % 60) : "0" + Math.round(s % 60); // 秒
    if (m > 60) {
      // 存在时
      h =
        Math.round(m / 60) >= 10
          ? Math.round(m / 60)
          : "0" + Math.round(m / 60);
      m =
        Math.round(m % 60) >= 10
          ? Math.round(m % 60)
          : "0" + Math.round(m % 60);
    }
  } else if (s < 10) {
    s = "0" + s;
  }
  if (h > 0) {
    result = h + ":";
  } else {
    result = "00:";
  }
  if (m > 0) {
    result = result + m + ":";
  } else {
    result = result + "00:";
  }
  result = result + s; // 秒必须出现无论是否大于零
  return result;
}

export const timeShow = (t, style) => {
  let str = ''
  const time = new Date(t || new Date())
  const y = time.getFullYear()
  const m = time.getMonth() > 8 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1))
  const d = time.getDate() > 9 ? time.getDate() : ('0' + time.getDate())
  const h = time.getHours() > 9 ? time.getHours() : ('0' + time.getHours())
  const min = time.getMinutes() > 9 ? time.getMinutes() : ('0' + time.getMinutes())
  const s = time.getSeconds() > 9 ? time.getSeconds() : ('0' + time.getSeconds())
  switch (style) {
    case 'YY-MM-DD':
      str = y.toString() + '-' + m.toString() + '-' + d.toString()
      break
    case 'YY/MM/DD':
      str = y.toString() + '/' + m.toString() + '/' + d.toString()
      break
    case 'YY.MM.DD':
      str = y.toString() + '.' + m.toString() + '.' + d.toString()
      break
    case 'YYMMDD':
      str = y.toString() + m.toString() + d.toString()
      break
    case 'YY-MM-DD HH-MM-SS':
      str = y.toString() + '-' + m.toString() + '-' + d.toString() + ' ' + h.toString() + '-' + min.toString() + '-' + s.toString()
      break
    case 'YY-MM-DD HH:MM:SS':
      str = y.toString() + '-' + m.toString() + '-' + d.toString() + ' ' + h.toString() + ':' + min.toString() + ':' + s.toString()
      break
    case 'YY':
      str = y.toString()
      break
    case 'YYMM':
      str = y.toString() + m.toString()
      break
    default:
      break
  }
  return str
}
export const calculation = (num) => {
  const tar = Math.abs(num);
  const isfu = Number(num) < 0 ? true : false;
  if (tar < 1024) {
    return isfu ? ('-' + tar.toString() + "B") : (tar.toString() + "B");
  }
  if (tar >= 1024 && tar < 1048576) {
    return isfu ? ((
      '-' + (Math.floor(Number(tar / 1024) * 100) / 100).toFixed(2)
        .toString() + "KB"
    )) : (
        (Math.floor(Number(tar / 1024) * 100) / 100).toFixed(2)
          .toString() + "KB"
      );
  }
  if (tar >= 1048576 && tar < 1073741824) {
    return isfu ? ((
      '-' + (Math.floor(Number(tar / 1048576) * 100) / 100).toFixed(2)
        .toString() + "MB"
    )) : (
        (Math.floor(Number(tar / 1048576) * 100) / 100).toFixed(2)
          .toString() + "MB"
      );
  }
  if (tar >= 1073741824 && tar < 1099511627776) {
    return isfu ? ((
      '-' + (Math.floor(Number(tar / 1073741824) * 100) / 100).toFixed(2)
        .toString() + "GB"
    )) : (
        (Math.floor(Number(tar / 1073741824) * 100) / 100).toFixed(2)
          .toString() + "GB"
      );
  }
  if (num >= 1099511627776) {
    return (
        (Math.floor(Number(Number(num / 1099511627776)) * 100) / 100).toFixed(2)
        .toString() + "TB"
    );
  }
}

export function openWindow(url, title, w, h) {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  const left = ((width / 2) - (w / 2)) + dualScreenLeft
  const top = ((height / 2) - (h / 2)) + dualScreenTop
  const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}
