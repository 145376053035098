/*
 * @Author: xuqiang xuqiang@datatang.com
 * @Date: 2022-08-18 09:52:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-18 19:43:53
 * @FilePath: \evaluation-protal\src\utils\orgId.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const orgIdKey = "resources_orgId";

export function getOrgId() {
  var orgId = localStorage.getItem(orgIdKey);
  return orgId && +orgId
}

export function setOrgId(orgId) {
  return localStorage.setItem(orgIdKey,  orgId);
}

export function removeOrgId() {
  return localStorage.removeItem(orgIdKey);
}