/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/store/modules/portal.js
 * @Date: 2022-07-17 16:54:26
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
const state = {
  schemeDetailTabCheck: "1",
  currentPlayerAudio: null,
  currentPlayerVideo: null,
};
const mutations = {
  SET_SCHEME_DETAIL_TAB: (state, tab) => {
    state.schemeDetailTabCheck = tab;
  },
  SET_CUR_PLAY_AUDIO: (state, data) => {
    state.currentPlayerAudio = data;
  },
  SET_CUR_PLAY_VIDEO: (state, data) => {
    state.currentPlayerVideo = data;
  },
};
const actions = {
  updateSchemeDetailTab({ commit }, data) {
    commit("SET_SCHEME_DETAIL_TAB", data);
  },
  updateCurPlayAudio({ commit }, data) {
    commit("SET_CUR_PLAY_AUDIO", data);
  },
  updateCurPlayVideo({ commit }, data) {
    commit("SET_CUR_PLAY_VIDEO", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
