/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\store\getters.js
 * @Date: 2022-07-04 14:03:14
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
const getters = {
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  role: (state) => state.user.role,
  isAuth: (state) => state.user.isAuth,
  userId: (state) => state.user.userId,
  userName: (state) => state.user.userName,
  newToken: (state) => state.user.newToken,
  loginPop: (state) => state.user.loginPop,
  amendNum: (state) => state.user.amendNum,
  uploadStatus: (state) => state.upload.uploadStatus,
  cancelStatus: (state) => state.upload.cancelStatus,
  schemeDetailTabCheck: (state) => state.portal.schemeDetailTabCheck,
  currentPlayerAudio: (state) => state.portal.currentPlayerAudio,
  currentPlayerVideo: (state) => state.portal.currentPlayerVideo,
  orgId: (state) => state.user.orgId,
  account: (state) => state.user.account,
  isInvitation: (state) => state.user.isInvitation,
  allowOpenScheme:(state) => state.user.allowOpenScheme,
  uid: (state) => state.user.uid,
  business: (state) => state.user.business,
  oauthName: (state) => state.user.oauthName,
  client: (state) => state.user.client,
  loginName:(state) => state.user.loginName,
  loginOrgId:(state) => state.user.loginOrgId,
  orgName:(state) => state.user.orgName,
  taskOrSchemeAdmin:(state) => state.user.taskOrSchemeAdmin,
  orgAS:(state)=> state.user.orgAS,
  fileStatus:(state) => state.upload.fileStatus,
  uploadId:(state) => state.upload.uploadId,
  uploadCompleteData:(state) => state.upload.uploadCompleteData,
};
export default getters;
