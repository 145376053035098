<template>
  <div class="pagination-container">
    <el-pagination
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size.sync="pageSize"
      :layout="layoutCom"
      :total="total"
      :pager-count='pagerCount'
      :hide-on-single-page="total === 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: "DtPagination",
  props: {
    total: {
      // 总页数
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: "total,sizes,prev,pager,next",
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 30, 50];
      },
    },
    pagerCount:{
      type:Number,
      default:7
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
    layoutCom() {
      if (this.isSmall) {
        return "prev, pager, next";
      }
      return this.layout;
    },
  },
  created() {
    // this.$store.dispatch("app/pageSize", 30);
  },
  methods: {
    handleSizeChange(val) {
      // this.$store.dispatch("app/pageSize", val);
      this.$emit("pagination", { page: this.currentPage, limit: val });
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  padding: 20px 0;
  text-align: right;
  margin-top: 0 !important;
}
::v-deep .el-pager li {
  background: transparent;
  font-weight: normal;
  font-size: 16px;
}
::v-deep .el-pagination .btn-next {
  background: transparent;
}
::v-deep .el-pagination .btn-prev {
  background: transparent;
}
</style>
