/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\router\module\space.js
 * @Date: 2022-07-07 15:48:20
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 更多
export default [
  {
    path: "/:username",
    name: "account",
    meta: {
      title: "account",
      requiresAuth: true,
      show:false
    },
    component: () => import("@/views/account/index.vue"),
    children: [
      {
        path: "/:username/contribution",
        component: () => import("@/views/account/tabs/contribution.vue"),
        name: "contribution",
        meta: {
          title: "评测动态",
          requiresAuth: true,
          show:false
        },
      },
      {
        path: "/:username/schemes",
        component: () => import("@/views/account/tabs/schemes.vue"),
        name: "schemes",
        meta: {
          title: "参与的评测",
          requiresAuth: true,
          show:false
        },
      },
      {
        path: "/:username/schemes/release",
        component: () => import("@/views/account/tabs/release.vue"),
        name: "release",
        meta: {
          title: "发布的方案",
          requiresAuth: true,
          show:false
        },
      },
    ],
  },
];
