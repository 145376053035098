/*
 * @Author: xuqiang xuqiang@datatang.com
 * @Date: 2022-08-18 21:29:22
 * @LastEditors: xuqiang xuqiang@datatang.com
 * @LastEditTime: 2022-11-24 17:38:34
 * @FilePath: /evaluation-protal/src/router/module/toolManagement.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 工具管理
export default [
    {
        path: "/resources/tools",
        meta: {
            title: "工具管理",
        },
        component: () => import("@/views/toolManagement/index.vue"),
        redirect: "/resources/tools",
        children: [
            {
                path: "/",
                name: "toolManagement",
                meta: {
                    title: "工具管理",
                },
                component: () => import("@/views/toolManagement/toolList"),
            },
            {
                path: "new",
                name: "toolNew",
                meta: {
                    title: "新建工具",
                    activeMenu: "/resources/tools",
                },
                component: () => import("@/views/toolManagement/toolNew"),
            },
            {
                path: ":toolId",
                name: "toolId",
                meta: {
                    title: "编辑工具",
                    activeMenu: "/resources/tools",
                },
                component: () => import("@/views/toolManagement/toolNew"),
            },
            {
                path: "view/:toolId",
                name: "lookTool",
                meta: {
                    title: "查看工具",
                    activeMenu: "/resources/tools",
                },
                component: () => import("@/views/toolManagement/toolNew"),
            },
            {
                path: ":toolId/test/:direction/:evaluateType",
                name: "ToolTest",
                meta: {
                    title: "工具调试",
                    // activeMenu: "/resources/tools",
                },
                component: () => import("@/views/toolManagement/toolTest"),
            },
            {
                path: ":toolId/modelTrans",
                name: "ModelTrans",
                beforeEnter: (to, from, next) => {
                    console.log(to)
                    if (to.query.btnType === "trans") {
                        to.meta.title = "模型转换";
                    } else {
                        to.meta.title = "模型可视化";
                    }
                    next();
                },
                component: () => import("@/views/toolManagement/modelTrans"),
            },
        ],
    },
];
