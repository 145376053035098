/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\main.js
 * @Date: 2022-07-04 13:41:02
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/element.js";
import "@/icons"; // icon
import "./theme/index.css"; //  custom theme
import "@/styles/index.scss"; // global css
import "@/permission"; // permission control
import "@/components/dt/index"; //  custom components
import "@/components/ef/index.css";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
Vue.use(VueVideoPlayer);
// img
import "viewerjs/dist/viewer.css";
import Vuer from "v-viewer";
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/atom-one-dark.css";
Vue.use(VueHighlightJS);
Vue.use(Vuer);
Vue.config.productionTip = false;
import Login from "@/views/login/login.js";
Vue.prototype.$Login = Login.install;
// 流量值/文件大小转换
Vue.filter("formatbytes", function (bytes) {
  if (!bytes) return "0 KB";
  var k = 1024; // or 1024
  var sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
});

import directives from '@/utils/directives'
Vue.use(directives)
/* 正则使用'\S'而不是'\d' 为了适配写魔法注释的朋友，写'\d'遇到魔法注释就匹配不成功了。
 * 使用reload方法而不是replace原因是replace还是去请求之前的js文件，会导致循环报错。
 * reload会刷新页面， 请求最新的index.html以及最新的js路径。
 * 直接修改location.href或使用location.assign或location.replace，和router.replace同理，
 * 在当前场景中请求的依然是原来的js文件，区别仅有浏览器的历史栈。因此必须采用reload.
 * reload()有个特点是当你在A页面试图进入B页面的时候报错，会在A页面刷新，因此在刷新后需要手动书写逻辑
 * 进入B页面，可以在router.onReady()方法里面书写
 * 为了避免在特殊情况下服务器丢失资源导致无限报错刷新，做了一步控制，仅尝试一次进入B页面，
 * 如果不成功就只刷新A页面，停留在当前的A页面。
 */
router.onError((error) => {
  const jsPattern = /Loading chunk (\S)+ failed/g;
  const cssPattern = /Loading CSS chunk (\S)+ failed/g;
  const isChunkLoadFailed = error.message.match(jsPattern || cssPattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    localStorage.setItem("targetPath", targetPath);
    window.location.reload();
  }
});
router.onReady(() => {
  const targetPath = localStorage.getItem("targetPath");
  const tryReload = localStorage.getItem("tryReload");
  if (targetPath) {
    localStorage.removeItem("targetPath");
    if (!tryReload) {
      router.replace(targetPath);
      localStorage.setItem("tryReload", true);
    } else {
      localStorage.removeItem("tryReload");
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
