/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\portal.js
 * @Date: 2022-07-08 12:50:46
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 门户
export default [
  {
    path: "/",
    component: () => import("@/views/portal/index.vue"),
    name: "portal",
    meta: {
      title: "首页",
    },
  },
  {
    path: "/schemes/:schemeId",
    component: () => import("@/views/portal/scheme/details.vue"),
    name: "Ranking",
    meta: {
      title: "方案详情",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/data",
    component: () => import("@/views/portal/scheme/staticPage/data.vue"),
    name: "Ranking",
    meta: {
      title: "方案详情-图表-静态页-临时",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/intro",
    component: () => import("@/views/portal/scheme/intro.vue"),
    name: "Intro",
    meta: {
      title: "方案说明",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/caseAbout",
    component: () => import("@/views/portal/caseAbout/index.vue"),
    name: "caseAboutRanking",
    meta: {
      title: "方案详情",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/intro/caseAbout",
    component: () => import("@/views/portal/caseAbout/index.vue"),
    name: "caseAboutIntro",
    meta: {
      title: "方案说明",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/issues",
    component: () => import("@/views/portal/scheme/issuesLst.vue"),
    name: "Issues",
    meta: {
      title: "issues",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/issues/new",
    component: () => import("@/views/portal/scheme/newIssue.vue"),
    name: "NewIssue",
    meta: {
      title: "New Issue",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/issues/:questionId",
    component: () => import("@/views/portal/scheme/issueDetails.vue"),
    name: "IssueDetails",
    meta: {
      title: "问题详情",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/enroll",
    component: () => import("@/views/portal/scheme/applyScheme.vue"),
    name: "Enroll",
    meta: {
      title: "报名",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/othersTask/:taskId/:userId",
    component: () => import("@/views/portal/scheme/othersTask.vue"),
    name: "OthersTask",
    meta: {
      title: "他的任务",
      activeMenu: "/direction",
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/:schemeId/myTask/:flag?",
    component: () => import("@/views/portal/scheme/myTask.vue"),
    name: "MyTask",
    meta: {
      title: "我的任务",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/myTask/:taskId/issues/:questionId",
    component: () => import("@/views/portal/scheme/myTaskIssuesInfo.vue"),
    name: "MyTaskIssuesInfo",
    meta: {
      title: "问题详情",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/tasks/:taskId/:userId/issues/new",
    component: () => import("@/views/portal/scheme/taskNewIssue.vue"),
    name: "TaskNewIssue",
    meta: {
      title: "New Issue",
      activeMenu: "/direction",
    },
  },
  {
    path: "/schemes/:schemeId/tasks/:taskId/:userId/issues/:questionId",
    component: () => import("@/views/portal/scheme/taskIssueDetails.vue"),
    name: "TaskIssueDetails",
    meta: {
      title: "问题详情",
      activeMenu: "/direction",
    },
  },
];
