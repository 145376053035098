/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\api\memberManage\index.js
 * @Date: 2022-08-09 13:46:57
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */

import request from "@/utils/request";
const { get, post, delete: del, put } = request({});

// 组织成员列表
export function getMembersApi(data) {
  return get({
    url: `/organization/members`,
    data,
  });
}
// 组织角色
export function getRolesApi(data) {
  return get({
    url: `/organization/members/roles`,
    data,
  });
}
// 删除成员
export function delMemberApi(homeOrgId, userId) {
  return del({
    url: `/organization/${homeOrgId}/members/${userId}`,
  });
}
// 更新组员角色
export function updateRoleApi(homeOrgId, userId, role) {
  return put({
    url: `/organization/${homeOrgId}/members/${userId}/${role}`,
  });
}
// 邀请
export function inviteApi(orgId, userId, role) {
  return get({
    url: `/organization/invitation/${orgId}/${userId}/${role}`,
  });
}
// 查询用户
export function filterUserApi(data) {
  return get({
    url: '/organization/user/filter',
    data
  });
}
// 根据用户编号以及组织编号查询用户信息
export function getUserInfoOfOrgApi(userId, orgId) {
  return get({
    url: `/organization/user/${userId}/${orgId}`,
  });
}
// 应用凭证初始化
export function accessKeyInfoApi(orgId) {
  return get({
    url: `/organization/${orgId}/accesskey`,
  });
}
// 应用凭证初始化
export function resetaccessKeyApi(orgId,data) {
  return get({
    url: `/organization/${orgId}/reset/accesskey`,
    data,
  });
}