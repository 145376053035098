// 标准管理
export default [
    {
        path: "/resources/standards",
        meta: {
            title: "标准管理",
        },
        component: () => import("@/views/standardManagement/index.vue"),
        redirect: "/resources/standards",
        children: [
            {
                path: "/",
                name: "standardManagement",
                meta: {
                    title: "标准管理",
                },
                component: () => import("@/views/standardManagement/standardList"),
            },
            {
                path: "new",
                name: "standardNew",
                meta: {
                    title: "新建标准",
                    activeMenu: "/resources/standards",
                },
                component: () => import("@/views/standardManagement/standardNew"),
            },
            {
                path: "view/:standardId",
                name: "lookStandard",
                meta: {
                    title: "查看标准",
                    activeMenu: "/resources/standards",
                },
                component: () => import("@/views/standardManagement/standardNew"),
            },
            {
                path: ":standardId",
                name: "standardId",
                meta: {
                    title: "编辑标准",
                    activeMenu: "/resources/standards",
                },
                component: () => import("@/views/standardManagement/standardNew"),
            },
        ],
    },
];
