<template>
  <div>
    <div class="bindTelContainer">
      <div class="category">
        <ul>
          <li>绑定手机</li>
        </ul>
      </div>
      <p class="boxTip">请输入需绑定的手机号</p>
      <el-form
          ref="bindTelForm"
          :model="bindTelObj"
          :rules="bindTelRules"
          class="formBox bindTelForm"
      >
        <!-- 手机号 -->
        <el-form-item prop="telephone">
          <span class="svg-container">
            <svg-icon icon-class="phone" />
          </span>
          <el-input
              v-model="bindTelObj.telephone"
              placeholder="请输入手机号"
              type="text"
              @change="deleteSpace(bindTelObj.telephone,'telephone')"
          />
        </el-form-item>
        <!-- 短信验证码 -->
        <el-form-item prop="smsCode" class="shortFrame" style="margin-bottom:16px">
          <NoteVaild
              :isUsable="isCompanyName"
              :number="bindTelObj.telephone"
              :mode="mode"
              @handleNote="handleNote"
          ></NoteVaild>
          <el-input
              v-show="false"
              v-model="bindTelObj.smsCode"
              placeholder="请输入短信验证码"
              type="text"
              @change="deleteSpace(bindTelObj.smsCode,'smsCode')"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button
            type="primary"
            size="default"
            style="width:100%;margin-top:30px"
            @click.native.prevent="handleVerify"
        >确认绑定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import {telephone,isJoinBlacklist} from "@/api/user";
import {mapGetters} from "vuex";
import {setToken} from "@/utils/auth";
import { regExpObj } from "@/utils/validate";
export default {
  name: "ScanBindTel",
  components: { NoteVaild },
  data() {
    return {
      bindTelObj: {
        telephone: "",
        smsCode: "",
      },
      // 当前环节修改手机号
      mode: "OAUTHLOGIN",
      isCompanyName: false, // 名称是否走唯一性校验
    }
  },
  computed: {
    ...mapGetters(["uid", "business", "oauthName"]),
    bindTelRules() {
      // 手机号校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.phoneExp;
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          // this.isCompanyName = true;
          // callback();
           // 校验手机号是否被加入黑名单
          const res = await isJoinBlacklist({
            keyword: value,
          });
          if (res.status === 200) {
            if (!res.data.exist) {
              this.isCompanyName = false;
              callback(new Error("此手机号无法绑定"));
            } else {
              this.isCompanyName = true;
              callback();
            }
          } else {
            this.isCompanyName = false;
            callback(new Error(res.msg));
          }
        }
      };
      return {
        telephone: [
          {
            required: true,
            validator: phoneRule,
            trigger: "blur"
          }
        ],
        smsCode: [
          {
            required: true,
            message: "短信验证码不能为空",
            trigger: "blur"
          }
        ],
      };
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      if (keyword === "telephone") {
        this.isCompanyName = false;
      }
      this.bindTelObj[keyword] = value.trim();

    },
    // 保存短信验证码值
    handleNote(data) {
      this.bindTelObj.smsCode = data
    },
    // 绑定
    handleVerify() {
      this.$refs.bindTelForm.validate(valid => {
        if (valid) {
          if(!this.isCompanyName)return 
          this.handleRegist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleRegist() {
      this.bindTelObj.uId = this.uid;
      this.bindTelObj.business = this.business;
      this.bindTelObj.oauthName = this.oauthName;
      this.bindTelObj.mode = this.business==='2'?this.mode:'';
      console.log(this.bindTelObj)
      const res = await telephone(this.bindTelObj);
      if (res.status === 200) {
        this.$message({
          message: '登录成功',
          type: 'success'
        });
        const data = res.data;
        var info = { ti: Date.now(), token: data.token };
        console.log(info);
        this.$store.commit('user/SET_TOKEN', info)
        setToken(info);
        this.$emit('handleClose');
        window.location.href = '/'
      } else {
        res.status === 401 && this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.bindTelContainer {
  padding: 55px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .boxTip {
    text-align: center;
    color: #999;
    padding: 25px 0 8px;
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }
    .el-input {
      display: inline-block;
      width: 90%;

      ::v-deep input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        caret-color: #999;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: #333 !important;
        }
      }
    }
    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.shortFrame {
        border: 0;
      }
    }
  }
}
</style>
