/*
 * @Author: xuqiang xuqiang@datatang.com
 * @Date: 2022-11-07 09:36:19
 * @LastEditors: xuqiang xuqiang@datatang.com
 * @LastEditTime: 2022-11-07 10:39:29
 * @FilePath: /evaluation-protal/src/utils/directives.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"

export default {}.install = (Vue,option={})=>{
  Vue.directive('loadmore',{
    inserted(el,binding){
      const SELECT_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
      SELECT_DOM.addEventListener('scroll', function() {
        const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
        if (CONDITION) {
          binding.value()
        }
      })
    }
  })
}