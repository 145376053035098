<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\views\login\components\loginPage.vue
 * @Date: 2022-07-14 15:56:37
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="loginContainer">
    <div class="category">
      <ul>
        <li :class="{ active: loginMenu }" @click="handleMenu(true)">
          密码登录
        </li>
        <li :class="{ active: !loginMenu }" @click="handleMenu(false)">
          短信登录
        </li>
      </ul>
    </div>
    <!-- 账户密码 -->
    <el-form
      v-show="loginMenu"
      ref="userPassForm"
      :model="userPassObj"
      :rules="userPassRules"
      class="formBox userPassForm"
    >
      <!-- 账号邮箱 -->
      <el-form-item prop="loginName">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          v-model="userPassObj.loginName"
          maxlength="30"
          placeholder="请输入账号名/手机号/邮箱"
          type="text"
        />
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="pass" />
        </span>
        <el-input
          v-model="userPassObj.password"
          type="password"
          placeholder="请输入登录密码"
        />
      </el-form-item>
      <!-- 图形验证码 -->
      <el-form-item prop="verCode" class="shortFrame">
        <GraphicVaild
          ref="graphicInputs"
          :mode="mode"
          @handleGraphic="handleGraphic"
        ></GraphicVaild>
        <el-input
          v-show="false"
          v-model="userPassObj.verCode"
          placeholder="请输入图形验证码"
          type="text"
        />
      </el-form-item>
    </el-form>
    <!-- 手机号登录 -->
    <el-form
      v-show="!loginMenu"
      ref="phoneForm"
      :model="phoneObj"
      :rules="phoneRules"
      class="formBox telForm"
    >
      <!-- 手机号 -->
      <el-form-item prop="telephone">
        <span class="svg-container">
          <svg-icon icon-class="phone" />
        </span>
        <el-input
          v-model="phoneObj.telephone"
          placeholder="请输入手机号"
          type="text"
        />
      </el-form-item>
      <!-- 图形验证码 -->
      <el-form-item prop="verCodes" class="shortFrame">
        <GraphicVaild
          ref="graphicInput"
          :mode="mode"
          @handleGraphic="handleGraphic"
        ></GraphicVaild>
        <el-input
          v-show="false"
          v-model="phoneObj.verCode"
          placeholder="请输入图形验证码"
          type="text"
        />
      </el-form-item>
      <el-form-item prop="smsCode" class="shortFrame">
        <NoteVaild
          ref="noteInput"
          :isUsable="isUsable && checkTel(phoneObj.telephone)&&isCompanyName"
          :number="phoneObj.telephone"
          :mode="mode"
          @handleNote="handleNote"
        ></NoteVaild>
        <el-input
          v-show="false"
          v-model="phoneObj.smsCode"
          placeholder="请输入短信验证码"
          type="text"
        />
      </el-form-item>
      <el-form-item prop="protocol" class="shortFrame">
        <div class="checkBox">
          <el-checkbox v-model="protocol" @change="handleClearTip"></el-checkbox>
          <div style="padding-left: 10px; line-height: 18px">
            <Agreement />
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="passBox" v-if="loginMenu" :style="!userSystemLst.length?'margin-bottom: 24px':''">
      <el-select
        v-model="userSystem"
        placeholder="请选择"
        style="margin-top: 10px; width: 200px"
        clearable
        v-if="userSystemLst.length > 0"
        @clear="clearHandler"
      >
        <template slot="prefix" v-if="getIcon">
          <img
            :src="getIcon"
            alt=""
            style="
              width: 16px;
              height: 16px;
              position: relative;
              top: 12px;
              margin-left: 5px;
            "
          />
        </template>
        <el-option
          v-for="item in userSystemLst"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          style="width: 230px;"
        >
          <img
            :src="item.icon"
            alt=""
            style="
              width: 16px;
              height: 16px;
              line-height: 16px;
              vertical-align: middle;
              margin-right: 10px;
            "
          />
          <span> {{ item.name }}</span>
        </el-option>
      </el-select>
      <p @click="handleCut('3')">忘记密码？</p>
    </div>
    <p style="font-size:12px;color:#999;margin:5px 0;" v-if="userSystemLst.length > 0&&loginMenu">
      平台注册用户登录时，无需选择
    </p>
    <div>
      <el-button
        type="primary"
        size="default"
        style="width: 100%; margin-bottom: 10px; font-size: 14px; font-weight: bold"
        @click.native.prevent="handleVerify"
        >登录</el-button
      >
      <p class="goRegister" @click="handleCut('2')">立即注册</p>
      <el-divider>其他登录方式</el-divider>
      <ul class="otherBox">
        <li>
          <svg-icon
            @click="handleCut('8')"
            :icon-class="wechatIcon"
            @mouseenter="handleWechat('wechatHover')"
            @mouseleave="handleWechat('wechat')"
          ></svg-icon>
        </li>
        <li>
          <svg-icon
            @click="handleCut('9')"
            :icon-class="dingIcon"
            @mouseenter="handleDing('dingHover')"
            @mouseleave="handleDing('ding')"
          ></svg-icon>
        </li>
        <li style="padding-right:0px">
          <svg-icon
            @click="handleGithub"
            :icon-class="gitIcon"
            @mouseenter="handleGit('gith')"
            @mouseleave="handleGit('git')"
          ></svg-icon>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import { setToken } from "@/utils/auth";
import { passwordRule } from "@/utils/validate";
import GraphicVaild from "@/components/graphicVaild/index";
import { checkPicBlank, getUserType,isJoinBlacklist,getGithubRender,githubLogin } from "@/api/user";
import Agreement from "@/views/agreement/index.vue";
import md5 from "js-md5";
import { encrypt } from "@/utils/crypto";
import { openWindow } from "@/utils/common";
import { regExpObj } from "@/utils/validate";

export default {
  name: "LoginPage",
  components: { GraphicVaild, NoteVaild, Agreement },
  data() {
    return {
      protocol: false, // 协议是否勾选
      // 切换登录方式
      loginMenu: true, // true账号密码，false短信
      // 账号密码登录
      userPassObj: {
        loginName: "",
        password: "",
        verCode: "", //图像验证码值
      },
      phoneObj: {
        telephone: "",
        smsCode: "",
        verCode: "", //图像验证码值
      },
      // 图形验证码key
      verKey: "",
      mode: "LOGIN", // 获取图形验证码当前场景
      // checked: false, //记住密码
      wechatIcon: "wechat", //其他登录方式图标
      dingIcon: "ding", //其他登录方式图标
      gitIcon:'git',
      isUsable: false, // 获取验证码按钮是否可用
      userSystem: "", //选中项id
      authType: "", //用户体系类型区分
      userSystemLst: [],
      isCompanyName: false, // 判断手机号是否加入黑名单
    };
  },
  computed: {
    getIcon() {
      let str = "";
      this.userSystemLst.forEach((item) => {
        if (item.id === this.userSystem) {
          str = item.icon;
          this.authType = "LDAP"
        }
      });
      return str;
    },
    // 账号密码登录
    userPassRules() {
      return {
        loginName: [
          {
            required: true,
            message: "账号/手机号/邮箱不能为空",
            trigger: "blur",
          },
        ],
        // 密码校验
        password: [
          { required: true, trigger: "blur", validator: passwordRule },
        ],
        verCode: [
          {
            required: true,
            message: "图形验证码不能为空",
            trigger: "blur",
          },
        ],
      };
    },

    // 手机号登录
    phoneRules() {
      // 协议校验
      const validProtocol = (rule, value, callback) => {
        if (!this.protocol) {
          callback(new Error("请阅读并同意协议条款"));
        } else {
          callback();
        }
      };
      // 图形验证码校验
      const validCode = async (rule, value, callback) => {
        if (this.phoneObj.verCode === "") {
          callback(new Error("图形验证码不能为空"));
        } else {
          // if (this.isUsable) {
          //   return callback();
          // }
          // const res = await checkPicBlank({
          //   mode: this.mode,
          //   verKey: this.verKey,
          //   verCode: this.phoneObj.verCode,
          // });
          // if (res.status === 200) {
          this.isUsable = true;
          callback();
          // } else {
          //   this.isUsable = false;
          //   this.$refs.graphicInput.handleGetCode();
          //   callback(new Error(res.msg));
          // }
        }
      };
      // 手机号校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.phoneExp;
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          // 校验手机号是否被加入黑名单
          const res = await isJoinBlacklist({
            keyword: value,
          });
          if (res.status === 200) {
            if (!res.data.exist) {
              this.isCompanyName = false;
              callback(new Error("此手机号无法登录"));
            } else {
              this.isCompanyName = true;
              callback();
            }
          } else {
            this.isCompanyName = false;
            callback(new Error(res.msg));
          }
        }
      };
      return {
        telephone: [
          {
            required: true,
            validator: phoneRule,
            trigger: "blur",
          },
        ],
        // 短信验证码
        smsCode: [
          { required: true, trigger: "blur", message: "短信验证码不能为空" },
        ],
        verCodes: [
          {
            required: true,
            validator: validCode,
            trigger: "blur",
          },
        ],
        protocol: [
          {
            required: true,
            validator: validProtocol,
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
  mounted() {
    this.getUserSystem();
    const that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      // 保证存在登录弹窗发出的enter事件
      if (
        document.getElementsByClassName("loginDialog").length > 0 &&
        (e.code === "Enter" || e.code === "enter")
      ) {
        // 调用登录函数
        that.handleVerify();
      }
    };
  },
  methods: {
    // 获取用户体系
    async getUserSystem() {
      let res = await getUserType({});
      if (res.status === 200) {
        this.userSystemLst = res.data;
      }
    },
    clearHandler() {
      this.userSystem = "";
      this.authType = "";
    },
    // 校验手机号格式
    checkTel(tel) {
      const rq = regExpObj.phoneExp;
      if (rq.test(tel)) {
        return true;
      }
      return false;
    },
    // 切换登录方式
    handleMenu(data) {
      this.loginMenu = data;
      // 账号密码
      if (this.loginMenu) {
        this.$refs.graphicInputs.handleGetCode();
        this.$refs.noteInput.handleClear();
        this.$refs.graphicInput.handleClear();
        this.$refs.phoneForm && this.$refs.phoneForm.resetFields();
      } else {
        // 手机号
        this.$refs.graphicInput.handleGetCode();
        this.$refs.graphicInputs.handleClear();
        this.$refs.userPassForm && this.$refs.userPassForm.resetFields();
      }
    },
    // 保存短信验证码值
    handleNote(data) {
      this.phoneObj.smsCode = data;
    },
    // 切换页面
    handleCut(data) {
      this.$emit("handleCut", data);
    },
    // 微信样式切换
    handleWechat(data) {
      this.wechatIcon = data;
    },
    // git样式切换
    handleGit(data){
      this.gitIcon = data
    },
    // 钉钉样式优化
    handleDing(data) {
      this.dingIcon = data;
    },
    // 保存图形验证码值
    handleGraphic(data) {
      this.loginMenu
        ? (this.userPassObj.verCode = data.verCode)
        : (this.phoneObj.verCode = data.verCode);
      this.verKey = data.verKey;
      this.isUsable = false;
    },
    // 跳转忘记密码
    switchPage() {
      this.$emit("switchPage", 2);
    },
    // 登录前校验
    handleVerify() {
      const verify = this.loginMenu ? "userPassForm" : "phoneForm";
      this.$refs[verify].validate((valid) => {
        if (valid) {
          if(!this.isCompanyName&&!this.loginMenu)return 
          this.handleLogin();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 登录开始
    handleLogin() {
      let params = {};
      // 账号密码
      if (this.loginMenu) {
        params = {
          loginName: this.userPassObj.loginName.trim(),
          password: this.userSystem
            ? encrypt(this.userPassObj.password)
            : md5(this.userPassObj.password),
          verCode: this.userPassObj.verCode.trim(),
          verKey: this.verKey,
          id: this.userSystem,
          type: this.userSystem ? this.authType : "PASSWORD",
        };
      } else {
        params = {
          telephone: this.phoneObj.telephone.trim(),
          smsCode: this.phoneObj.smsCode.trim(),
          verCode: this.phoneObj.verCode.trim(),
          verKey: this.verKey,
          type: "TELEPHONE",
        };
      }
      this.$store
        .dispatch("user/login", params)
        .then(() => {
          this.$emit("handleClose");
          location.reload();
        })
        .catch(() => {
          if (this.loginMenu) {
            this.$refs.graphicInputs.handleGetCode();
          } else {
            this.$refs.graphicInput.handleGetCode();
          }
        });
    },
    // github登录
    async handleGithub(){
      let res = await getGithubRender()
      if(res.status===200){
        openWindow(res.data.authorizeUrl,"github",540,540)
        window.addEventListener('message', this.loginGithubHandler, false);
      }
    },
    async loginGithubHandler(e) {
      console.log(e.data)
      let { uuid,username,loginType} = e.data;
      window.removeEventListener('message',this.loginGithubHandler,false)
      if (loginType === '0') {
        this.handleCut('11')
        this.$store.commit('user/SET_UID', uuid)
        this.$store.commit('user/SET_BUSINESS',"2")
        this.$store.commit('user/SET_OAUTHNAME',username)
        return 
      }
      if (uuid&&username) {
        this.$store.dispatch("user/gitLogin", {
          uuid:e.data.uuid,
          username:e.data.username
        })
        .then(() => {
          this.$emit("handleClose");
          location.reload();
        }).catch(() => {
          if (this.loginMenu) {
            this.$refs.graphicInputs.handleGetCode();
          } else {
            this.$refs.graphicInput.handleGetCode();
          }
        });
      }
    },
    // 清除协议提示
    handleClearTip(){
      this.$nextTick(() => {
        this.$refs["phoneForm"].clearValidate(["protocol"]);
      })
    }
  },
};
</script>
<style lang="scss">
.passBox {
  .el-input__inner {
    border-radius: 0px !important;
    height: 40px !important;
    line-height: 40px !important;
    // padding-left: 48px !important;
  }
}
</style>
<style lang="scss" scoped>
.loginContainer {
  padding: 35px 30px 20px;
  .blue {
    color: #337dff;
  }
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }
    .el-input {
      display: inline-block;
      width: 90%;

      ::v-deep input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        caret-color: #999;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: #333 !important;
        }
      }
    }
    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 18px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
      }

      &.shortFrame {
        border: 0;
      }
    }
  }
  .userPassForm {
    .el-form-item:last-child {
      margin-bottom: 10px;
    }
  }
  .passBox {
    min-height: 20px;
    overflow: hidden;
    position: relative;
    p {
      cursor: pointer;
      color: #337dff;
      // float: right;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      &:hover{
        color:#0D53CE;
      }
    }
  }
  .checkBox {
    display: flex;
    // justify-content: space-between;
    // padding-bottom: 19px;
    line-height: 16px;
    p {
      cursor: pointer;
      line-height: 20px;
      color: #333333;
    }
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #333333;
    }
  }
  .goRegister {
    color: #337dff;
    cursor: pointer;
    display: inline-block;
    &:hover{
      color:#0D53CE;
    }
  }
  .otherBox {
    display: flex;
    justify-content: center;
    li {
      font-size: 30px;
      flex:1;
      // padding: 0 40px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
