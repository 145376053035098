// 所有正则表达式
export const regExpObj = {
  // 5-25位，支持英文、数字、中划线，不能全部是数字，不能以中划线开头和结尾，不能出现连续的中划线
  accountExp: /^[0-9a-zA-Z][0-9a-zA-Z-]{3,23}[0-9a-zA-Z]$/,
  // 支持输入2-12个中文、英文或数字
  loginNameExp: /^[A-Za-z\d\u4e00-\u9fa5]{2,12}$/,
  // 支持输入英文大小写、数字及符号
  enNameExp: /^[a-zA-Z0-9 _!@\\#\\$\\%\\^\\&\\*\\(\\)-\\=\\+\\[\]\\{\\}\\~\\|]*$/,
  // 支持输入汉字、数字及符号
  zhNameExp: /^[\u4e00-\u9fa50-9 _!@\\#\\$\\%\\^\\&\\*\\(\\)-\\=\\+\\[\]\\{\\}\\~\\|]*$/,
  // 正整数
  numberExp: /^[0-9]+$/,
  // 纯中文
  chineseExp: /^[\u4e00-\u9fa5]+$/,
  //0-100中间的任意数字，但不能是01，02
  rateExp: /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,2})?$/,
  // 请输入6-18位密码，必须包含字母和数字(区分大小写)
  passWordExp: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/,
  // 邮箱校验
  emailExp: /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/,
  // 手机号校验
  phoneExp: /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/,
};
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

// 校验提示   基于 element UI
// 用户名校验  提示
// export const userNameRule = (rule, value, callback) => {
//   const re = /^[\u4e00-\u9fa5A-Za-z0-9]+$/;
//   if (value === "") {
//     callback(new Error("用户名不能为空"));
//   } else if (value.length > 18 || value.length < 6) {
//     callback(new Error("用户名长度为6到18位"));
//   } else if (!re.test(value)) {
//     callback(new Error("不能含有特殊字符！可以包含汉字英文以及数字"));
//   } else {
//     callback();
//   }
// };
// 密码长度校验  提示
export const passwordRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else if (value.length < 6) {
    callback(new Error("密码不能低于6位"));
  } else {
    callback();
  }
};
// 密码强弱判断
// export const strongPassWord = (rule, value, callback) => {
//   var strongRegex = new RegExp(
//     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
//   );
//   var mediumRegex = new RegExp(
//     "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
//   );
//   if (value.length < 8 || value.length > 20) {
//     callback(new Error("密码不能低于8位，最高支持20位"));
//   } else if (strongRegex.test(value)) {
//     // 强密码
//   } else if (mediumRegex.test(value)) {
//     // 中等密码
//   } else {
//     // 弱密码
//   }
// };
// 手机号格式校验  提示
// export const phoneRule = (rule, value, callback) => {
//   const re =
//     /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
//   if (value === "") {
//     callback(new Error("手机号不能为空"));
//   } else if (!re.test(value)) {
//     console.log("wrong");
//     callback(new Error("手机号格式不正确"));
//   } else {
//     console.log("right");
//     callback();
//   }
// };
// 邮箱校验  提示
// export const mailRule = (rule, value, callback) => {
//   const re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
//   if (!re(value)) {
//     callback(new Error("邮箱格式不正确"));
//   } else {
//     callback();
//   }
// };

// 注册  用户密码第一次输入   需要把this传给参数that！！
// export const firstPassWorld = (rule, value, callback, that) => {
//   if (value === "") {
//     callback(new Error("请输入密码"));
//   } else {
//     if (that.signForm.passcheck !== "") {
//       that.$refs.signForm.validateField("validatePass2");
//     }
//     callback();
//   }
// };
// 注册 用户密码第二次输入
// export const secPassWorld = (rule, value, callback, that) => {
//   if (value === "") {
//     callback(new Error("请再次输入密码"));
//   } else if (value !== that.signForm.pass) {
//     callback(new Error("两次输入密码不一致!"));
//   } else {
//     callback();
//   }
// };

// 时间戳转多少分钟之前
export function getDateDiff(dateTimeStamp, curTime) {
  // 时间字符串转时间戳
  var timestamp = new Date(dateTimeStamp).getTime();
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var halfamonth = day * 15;
  var month = day * 30;
  var year = day * 365;
  var now = curTime ? new Date(curTime).getTime() : new Date().getTime();
  var diffValue = now - timestamp;
  var result;
  if (diffValue < 0) {
    return;
  }
  var yearC = diffValue / year;
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (yearC >= 1) {
    result = "" + parseInt(yearC) + "年前";
  } else if (monthC >= 1) {
    result = "" + parseInt(monthC) + "月前";
  } else if (weekC >= 1) {
    result = "" + parseInt(weekC) + "周前";
  } else if (dayC >= 1) {
    result = "" + parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    result = "" + parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    result = "" + parseInt(minC) + "分钟前";
  } else result = "刚刚";
  return result;
}

// 获取当前日期往前推一周
export function whatDay() {
  var leng = 7;
  var now = new Date();
  var nowTime = now.getTime();
  var oneDayTime = 24 * 60 * 60 * 1000;
  var timeArr = [];
  for (var i = 0; i < leng; i++) {
    //显示星期
    var ShowTime = nowTime - (i + 1) * oneDayTime;
    //初始化日期时间
    var myDate = new Date(ShowTime);
    var year = myDate.getFullYear();
    var month = myDate.getMonth() + 1;
    var date = myDate.getDate();
    // var str = "星期" + "日一二三四五六".charAt(myDate.getDay());
    var str = year + "-" + month + "-" + date;
    // timeArr.push({
    //   week: str,
    //   date: date,
    //   day: myDate,
    // });
    timeArr.push(str);
  }
  timeArr.reverse();
  return timeArr;
}
// 节流
export function throttle(fn, wait = 200) {
  let last, timer, now;
  return function () {
    now = Date.now();
    if (last && now - last < wait) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.call(this, ...arguments);
      }, wait);
    } else {
      last = now;
      fn.call(this, ...arguments);
    }
  };
}
// 防抖
export function debounce(fn, wait = 200) {
  let timer;
  return function () {
    let context = this;
    let args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}
// 乘法
export const mul_fn = (a, b) => {
  var c = 0
  var d = a.toString()
  var e = b.toString()
  try {
    c += d.split('.')[1].length
  } catch (f) {console.log(f)}
  try {
    c += e.split('.')[1].length
  } catch (f) {console.log(f)}
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}
// 保留两位小数
export const keepToFixed = (x) => {
  if (isNaN(parseFloat(x))) {return false}
  var f = Math.round(mul_fn(x, 100)) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}