/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/router/module/putInStorage.js
 * @Date: 2022-08-03 13:43:14
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 任务管理
export default [
  {
    path: "/clusters/upload/progress",
    name: "PutInStorage",
    meta: {
      title: "入库进度",
      activeMenu: "/clusters/upload/progress",
    },
    component: () => import("@/views/clusters/upload/index.vue"),
  },
];
