/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\router\index.js
 * @Date: 2022-07-19 16:53:37
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/router/module/login";
import portal from "@/router/module/portal";
import evaluatingField from "@/router/module/evaluatingField";
import evaluatingCase from "@/router/module/evaluatingCase";
import explore from "@/router/module/explore";
import competition from "@/router/module/competition";
import more from "@/router/module/more";
import space from "@/router/module/space";
import settings from "@/router/module/settings";
import toolManagement from "@/router/module/toolManagement";
import algorithmManagement from "@/router/module/algorithmManagement";
import standardManagement from "@/router/module/standardManagement";
import memberManage from "@/router/module/memberManage";
import modelManage from "@/router/module/modelManage";
import taskManagement from "@/router/module/taskManagement";
import putInStorage from "@/router/module/putInStorage";
import projectManagement from "@/router/module/projectManagement";
import caseManagement from "@/router/module/caseManagement";
import jobManagement from "@/router/module/jobManagement";
import statisticalAnalysis from "@/router/module/statisticalAnalysis";
import routerMobile from "@/router/routerMobile";

const Layout = () => import("@/layout/index.vue");
const TaskLayout = () => import("@/layout/TaskCenterLayout");
Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
  ...login,
  {
    path: "/taskManagement",
    component: TaskLayout,
    children: [
      ...memberManage,
      ...modelManage,
      ...toolManagement,
      ...algorithmManagement,
      ...standardManagement,
      ...taskManagement,
      ...projectManagement,
      ...caseManagement,
      ...statisticalAnalysis,
      ...putInStorage,
      ...jobManagement
    ],
  },
  {
    path: "/",
    component: Layout,
    // 这里开始对应的路由都会显示在router-view中 如上图所示
    children: [
      ...portal,
      ...evaluatingField,
      ...evaluatingCase,
      ...explore,
      ...competition,
      ...more,
      ...settings,
      ...space,
    ],
  },
  { path: "*", redirect: "/404", hidden: true },
];

// 判断设备是否为移动端的方法
const isMobile = () => {
  /* return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
  ); */
  // 去除pad尺寸
  return window.screen.width<=1024;
  /* return /(phone|iPhone|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
); */
};

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: isMobile() ? routerMobile : routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
