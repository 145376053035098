import { login, logout, getInfo, refresh,githubLogin } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";
import { getOrgId, setOrgId, removeOrgId } from "@/utils/orgId";

const state = {
  token: getToken(),
  userName: "",
  avatar: "",
  introduction: "",
  role: "",
  isAuth: "",
  userId: "",
  account:"",
  loginPop: false,
  amendNum: "",
  loginName:"",
  orgId: getOrgId(),
  loginOrgId:"",
  orgName:"",
  orgList: [],
  isInvitation: false,
  allowOpenScheme: "1", // 新建方案公开设置权限 true:有公开权限:false:无公开权限
  uid: "",
  business: 0,
  oauthName: "",
  client:{}, //用户登录是否为ldap方式
  taskOrSchemeAdmin:false, // 是否有发布方案权限
  orgAS:''//组织简称
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, userName) => {
    state.userName = userName;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_LOGIN_POP: (state, type) => {
    state.loginPop = type;
  },
  SET_ISAUTH: (state, type) => {
    state.isAuth = type;
  },
  SET_USERID: (state, type) => {
    state.userId = type;
  },
  SET_AMEND_NUM: (state, num) => {
    state.amendNum = num;
  },
  SET_ORGID: (state, orgId) => {
    state.orgId = orgId;
  },
  SET_ORGLIST: (state, orgList) => {
    state.orgList = orgList;
  },
  SET_ACCOUT: (state, account) => {
    state.account = account;
  },
  SET_ISINVITA: (state, flag) => {
    state.isInvitation = flag;
  },
  SET_ALLOWOPENSCHEME: (state, allowOpenScheme) => {
    state.allowOpenScheme = allowOpenScheme;
  },
  SET_UID: (state, uid) => {
    state.uid = uid;
  },
  SET_BUSINESS: (state, business) => {
    state.business = business;
  },
  SET_OAUTHNAME: (state, oauthName) => {
    state.oauthName = oauthName;
  },
  SET_CLIENT: (state, item) => {
    state.client = item;
  },
  SET_LOGINNAME: (state, loginName) => {
    state.loginName = loginName;
  },
  SET_LOGINORGID: (state, loginOrgId) => {
    state.loginOrgId = loginOrgId;
  },
  SET_ORGNAME: (state, orgName) => {
    state.orgName = orgName;
  },
  SET_TASKORSCHEMEADMIN: (state, taskOrSchemeAdmin) => {
    state.taskOrSchemeAdmin = taskOrSchemeAdmin;
  },
  SET_ORGNAS: (state, account) => {
    state.orgAS = account;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      // const loginUrl = userInfo.smsCode ? telLogin : login;
      login(userInfo)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            var info = { ti: Date.now(), token: data.token };
            commit("SET_TOKEN", info);
            setToken(info);
            resolve();
            // 当处于用户中心页面 切换用户账号显示当前登录人账号信息
            if (
              router.history.current.name == "contribution" ||
              router.history.current.name == "schemes" ||
              router.history.current.name == "release"
            ) {
              router.replace({
                path: "/" + data.loginName + "/contribution",
              });
            }
          } else {
            reject(response.msg);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  gitLogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      githubLogin(userInfo)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            var info = { ti: Date.now(), token: data.token };
            commit("SET_TOKEN", info);
            // commit("SET_ROLES", data.roles);
            setToken(info);
            resolve();
            // 当处于用户中心页面 切换用户账号显示当前登录人账号信息
            if (
              router.history.current.name == "contribution" ||
              router.history.current.name == "schemes" ||
              router.history.current.name == "release"
            ) {
              router.replace({
                path: "/" + data.loginName + "/contribution",
              });
            }
          } else {
            reject(response.msg);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      // const loginUrl = userInfo.smsCode ? telLogin : login;
      refresh()
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            const data = response.data;
            var info = { ti: Date.now(), token: data.token };
            commit("SET_TOKEN", info);
            setToken(info);
            resolve();
          } else {
            reject(response.msg);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;
          if (!data) {
            reject("Verification failed, please Login again.");
          }

          const { role, userName, avatar, isAuth, userId, allowOpenScheme,clientId,icon,loginName,orgId,orgName,taskOrSchemeAdmin,account } =
            data;

          // roles must be a non-empty array
          // if (!roles || roles.length <= 0) {
          //   reject("getInfo: roles must be a non-null array!");
          // }

          commit("SET_ROLE", role);
          commit("SET_NAME", userName);
          commit("SET_AVATAR", avatar);
          commit("SET_ISAUTH", isAuth);
          commit("SET_USERID", userId);
          commit("SET_ALLOWOPENSCHEME", allowOpenScheme);
          commit('SET_CLIENT',{
            clientId:clientId,
            icon:icon
          })
          commit('SET_LOGINNAME',loginName)
          commit('SET_LOGINORGID',orgId)
          commit('SET_ORGNAME',orgName)
          commit('SET_ORGNAS',account)
          commit('SET_TASKORSCHEMEADMIN',taskOrSchemeAdmin)
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 存储orgId
  setOrgIdFn({ commit, state }, orgId) {
    commit("SET_ORGID", orgId);
    setOrgId(orgId);
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout()
        .then((response) => {
          if (response.status === 200) {
            commit("SET_USERID", "");
            commit("SET_TOKEN", "");
            commit("SET_AVATAR", "");
            removeToken();
            removeOrgId();
            resetRouter();
            window.location.href = "/";
            // reset visited views and cached views
            // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
            dispatch("tagsView/delAllViews", null, { root: true });
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      removeOrgId();
      resolve();
    });
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + "-token";

    commit("SET_TOKEN", token);
    setToken(token);

    const { roles } = await dispatch("getInfo");

    resetRouter();

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch("permission/generateRoutes", roles, {
      root: true,
    });
    // dynamically add accessible routes
    router.addRoutes(accessRoutes);

    // reset visited views and cached views
    dispatch("tagsView/delAllViews", null, { root: true });
  },
  setLoginPop({ commit }, state) {
    commit("SET_LOGIN_POP", state);
  },
  setName({ commit }, name) {
    commit("SET_NAME", name);
  },
  setAmendNum({ commit }, num) {
    commit("SET_AMEND_NUM", num);
  },
  setAccout({ commit }, account) {
    commit("SET_ACCOUT", account);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
