<template>
  <div class="authentContainer">
    <div class="category">
      <ul>
        <li>身份验证</li>
      </ul>
    </div>
    <p class="boxTip">为了您的账号安全，请先进行身份验证</p>
    <el-form
      ref="authentForm"
      :model="authentObj"
      :rules="authentRules"
      class="formBox authentForm"
    >
      <!-- 手机号 -->
      <el-form-item prop="telephone">
        <el-select v-model="authentObj.type" placeholder="请选择认证方式" style="width:308px">
          <el-option
            v-for="item in userConcat"
            :key="item.type"
            :label="item.number"
            :value="item.type"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 短信验证码 -->
      <el-form-item prop="smsCode" class="shortFrame" style="margin-bottom:16px">
        <NoteVaild :type="authentObj.type" :isUsable="true" :mode="mode" @handleNote="handleNote"></NoteVaild>
        <el-input
          v-show="false"
          v-model="authentObj.smsCode"
          placeholder="请输入短信验证码"
          type="text"
          @change="deleteSpace(authentObj.smsCode,'smsCode')"
        />
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="default"
        style="width:100%;margin-top:30px"
        @click.native.prevent="handleVerify"
      >验证</el-button>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import { checkSms, checkEmail, userConcat } from "@/api/user";

export default {
  name: "authent",
  components: { NoteVaild },
  props: {
    mode: {
      type: String,
      default() {
        return "TELEPHONE";
      }
    }
  },
  data() {
    return {
      authentObj: {
        type: "",
        smsCode: "",
      },
      userConcat: [], //当前用户已绑定的手机和邮箱

      isCompanyName: false, // 名称是否走唯一性校验
    }
  },
  computed: {
    authentRules() {
      return {
        smsCode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
      };
    }
  },
  watch: {
  },
  created() {
    this.getUserConcat();
  },
  methods: {
    // 加密回显当前用户已绑定的手机号及邮箱
    async getUserConcat() {
      this.userConcat = [];
      const res = await userConcat();
      if (res.status === 200) {
        if (res.data.telephone) {
          this.userConcat.push({ number: res.data.telephone, type: '1' });
          this.authentObj.type='1'
        }
        if (res.data.email) {
          this.userConcat.push({ number: res.data.email, type: '2' });
          this.authentObj.type='2'
        }
      }
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      if (keyword === "telephone") {
        this.isCompanyName = false;
      }
      this.authentObj[keyword] = value.trim();
    },
    // 保存短信验证码值
    handleNote(data) {
      this.authentObj.smsCode = data
    },
    // 校验
    handleVerify() {
      this.$refs.authentForm.validate(valid => {
        if (valid) {
          this.handleCheck();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleCheck() {
      let res = null
      if (this.authentObj.type === '2') {
        res = await checkEmail({ smsCode: this.authentObj.smsCode, mode: this.mode });
      } else {
        res = await checkSms({ smsCode: this.authentObj.smsCode, mode: this.mode });
      }
      if (res.status === 200) {
        const type = this.authentObj.type === '1' ? 'TELEPHONE' : "EMAIL";
        this.$emit("handleModify", { smsCode: this.authentObj.smsCode, type: type })
      } else {
        res.status === 401 && this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.authentContainer {
  padding: 55px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .boxTip {
    text-align: center;
    color: #999;
    padding: 25px 0 8px;
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }

    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
        .el-input {
          display: inline-block;
          width: 100%;

          input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 0 10px;
            color: #333;
            caret-color: #999;

            &:-webkit-autofill {
              box-shadow: 0 0 0px 1000px #fff inset !important;
              -webkit-text-fill-color: #333 !important;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.shortFrame {
        border: 0;
      }
    }
  }
}
</style>
