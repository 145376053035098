<template>
  <div>
    <div id="wechat" class="qrcode"></div>
    <span @click="handleCut('1')">手机快捷登录</span>
  </div>
</template>

<script>

export default {
  name: "weChat",
  data() {
    return {}
  },
  mounted() {
    this.wechatClick()
  },
  methods: {
    wechatClick(){// 点击切换微信扫码登录这一项，并向微信扫码登录
      // href 控制微信登录的样式 使用在线base64加密方法支持修改
      let url = window.g.weChatRedirectUri
      let obj = new WxLogin({
        id: "wechat",//wx组建元素
        appid: window.g.weChatAppid,//微信平台开放id
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(url),
        state: window.g.weChatState,
        style: "",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI1MHB4OyBib3JkZXI6IG5vbmV9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5zdGF0dXMge2NvbG9yOiAjODk4ZDkwO30gCi5pbXBvd2VyQm94IC5zdGF0dXMgcDpsYXN0LWNoaWxke2Rpc3BsYXk6IG5vbmV9IA==",
      });
    },
    // 切换页面
    handleCut(data) {
      this.$emit("handleCut", data)
      this.$emit('telLogin')
    },
  }
}
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  padding-top: 70px;
}
span {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-weight: 500;
  color: #337dff;
  font-size: 14px;
  cursor: pointer;
  &:hover{
    color:#0D53CE;
  }
}
</style>