<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\views\login\components\register.vue
 * @Date: 2022-07-14 15:56:37
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="registerContainer">
    <div class="category">
      <ul>
        <li>注册</li>
      </ul>
    </div>

    <el-form
      ref="registerForm"
      :model="registerForm"
      :rules="registerRules"
      class="formBox registerForm"
    >
      <!-- 选择注册方式 -->
      <el-select v-model="registrationWay" style="width:100%" class="registerSelect" @change="regisWayFn">
        <el-option
          v-for="item in registerLst"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- 手机号 -->
      <el-form-item prop="telephone" v-if="!registrationWay">
        <span class="svg-container">
          <svg-icon icon-class="phone" />
        </span>
        <el-input
          v-model="registerForm.telephone"
          placeholder="请输入手机号"
          type="text"
          @change="deleteSpace(registerForm.telephone,'telephone')"
        />
      </el-form-item>
      <!-- 邮箱号 -->
      <el-form-item prop="email" v-else>
        <span class="svg-container">
          <svg-icon icon-class="registerEmail" />
        </span>
        <el-input
          v-model="registerForm.email"
          placeholder="请输入邮箱号"
          type="text"
          @change="deleteSpace(registerForm.email,'email')"
        />
      </el-form-item>
      <!-- 图形验证码 -->
      <el-form-item prop="verCode" class="shortFrame">
        <GraphicVaild ref="graphicInput" :mode="mode" @handleGraphic="handleGraphic"></GraphicVaild>
        <el-input
          v-show="false"
          v-model="registerForm.verCode"
          placeholder="请输入验证码"
          type="text"
          @change="deleteSpace(registerForm.verCode,'verCode')"
        />
      </el-form-item>
      <!-- 短信验证码 -->
      <el-form-item prop="smsCode" class="shortFrame" style="margin-bottom:18px">
        <NoteVaild
          ref="noteVaildInput"
          :isUsable="isUsable&&isCompanyName"
          :number='getNumber'
          :mode="mode"
          @handleNote="handleNote"
        />
        <el-input
          v-show="false"
          v-model="registerForm.smsCode"
          placeholder="请输入验证码"
          type="text"
          @change="deleteSpace(registerForm.smsCode,'smsCode')"
        />
      </el-form-item>
      <el-form-item prop="protocol" class="shortFrame" style="margin-bottom:22px">
        <div class="passBox">
          <el-checkbox v-model="protocol" @change="handleClearTip"></el-checkbox>
          <div style="padding-left: 10px;">
            <Agreement />
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="default"
        style="width:100%;margin-bottom:10px"
        @click.native.prevent="handleVerify"
      >注册</el-button>
      <p class="goLogin">
        已有账号，
        <span @click="handleCut">立即登录</span>
      </p>
    </div>
  </div>
</template>
<script>
import GraphicVaild from "@/components/graphicVaild/index";
import NoteVaild from "@/components/noteVaild/index";
import { regExpObj } from "@/utils/validate";
import { register, checkTel, checkPicBlank,checkEmailExist,isJoinBlacklist } from "@/api/user";
import Agreement from '@/views/agreement/index.vue'
import { setToken } from "@/utils/auth";
export default {
  name: "Register",
  components: { GraphicVaild, NoteVaild, Agreement },
  data() {
    return {
      registrationWay:0,
      registerLst:[
        {
          value: 0,
          label: '手机号注册'
        },
        {
          value: 1,
          label: '邮箱号注册'
        }
      ],
      protocol: false, // 协议是否勾选
      registerForm: {
        telephone: "",
        email:"",
        verCode: "",
        smsCode: "",
        verKey: "",
      },
      // 当前环节模式，验证码需要
      mode: "REGISTER",
      isCompanyName: false, // 名称是否走唯一性校验
      isUsable: false, // 获取验证码按钮是否可用
    }
  },
  computed: {
    registerRules() {
      // 协议校验
      const validProtocol = (rule, value, callback) => {
        if (!this.protocol) {
          callback(new Error("请阅读并同意协议条款"));
        } else {
          callback();
        }
      };
      // 手机号校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.phoneExp;
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          if (this.isCompanyName) {
            return callback();
          }
          // 校验手机号是否被加入黑名单
          await this.verJoinBlacklist(this.registerForm.telephone)
          if(!this.isCompanyName){
            callback(new Error("此手机号无法注册"));
            return
          }
          // 手机号唯一性校验
          const res = await checkTel({
            telephone: this.registerForm.telephone,
          });
          if (res.status === 200) {
            if (!res.data.exist) {
              this.isCompanyName = false;
              callback(new Error("手机号已注册"));
            } else {
              this.isCompanyName = true;
              callback();
            }
          } else {
            this.isCompanyName = false;
            callback(new Error(res.msg));
          }
        }
      };
      // 邮箱号校验
      const emailRule = async (rule, value, callback) => {
        const re = regExpObj.emailExp;
        if (value === "") {
          callback(new Error("邮箱号不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("邮箱号格式不正确"));
        } else {
          if (this.isCompanyName) {
            return callback();
          }
          // 校验邮箱号是否被加入黑名单
          await this.verJoinBlacklist(this.registerForm.email)
          if(!this.isCompanyName){
            callback(new Error("此邮箱号无法注册"));
            return
          }
          // 邮箱号唯一性校验
          const res = await checkEmailExist({
            email: this.registerForm.email,
          });
          if (res.status === 200) {
            if (!res.data.exist) {
              this.isCompanyName = false;
              callback(new Error("邮箱号已注册"));
            } else {
              this.isCompanyName = true;
              callback();
            }
          } else {
            this.isCompanyName = false;
            callback(new Error(res.msg));
          }
        }
      };
      // 图形验证码校验
      const validCode = async (rule, value, callback) => {
        if (value === "") {
          this.isUsable = false;
          callback(new Error("图形验证码不能为空"));
        } else {
          if (this.isUsable) {
            return callback();
          }
          // 图像验证码对错校验
          const res = await checkPicBlank({
            mode: this.mode,
            verKey: this.registerForm.verKey,
            verCode: value,
          });
          if (res.status === 200) {
            this.isUsable = true;
            callback();
          } else {
            this.$refs.graphicInput.handleGetCode();
            this.isUsable = false;
            callback(new Error(res.msg));
          }
        }
      };
      return {
        telephone: [
          {
            required: true,
            validator: phoneRule,
            trigger: "blur"
          }
        ],
        email:[
          {
          required: true,
          validator: emailRule,
          trigger: "blur"
          }
        ],
        verCode: [
          {
            required: true,
            validator: validCode,
            trigger: "blur"
          }
        ],
        smsCode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
        protocol: [{
          required: true,
          validator: validProtocol,
          trigger: ["blur", "change"]
        }]
      };
    },
    getNumber(){
      return !this.registrationWay?this.registerForm.telephone:this.registerForm.email
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    // 回到登录页面
    handleCut() {
      this.$emit("handleCut", { type: '1' })
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      if (keyword === "telephone"||keyword === "email") {
        this.isCompanyName = false;
      }
      this.registerForm[keyword] = value.trim();
    },
    // 保存短信验证码值
    handleNote(data) {
      this.registerForm.smsCode = data
    },
    // 注册校验
    handleVerify() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.handleRegist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 注册
    async handleRegist() {
      let params = {
        verCode:this.registerForm.verCode,
        smsCode:this.registerForm.smsCode,
        verKey:this.registerForm.verKey,
        type:this.registrationWay?'EMAIL':'TELEPHONE'
      }
      this.registrationWay?params.email = this.registerForm.email:params.telephone = this.registerForm.telephone
      const res = await register(params);
      if (res.status === 200) {
        // 注册成功后直接登录
        var info = { ti: Date.now(), token: res.data.token };
        setToken(info);
        window.location.href = "/settings/account"
        // 注册成功后再手动登录
        // this.$emit("handleCut", { type: '4', status: '2' });
      } else {
        this.$refs.graphicInput.handleGetCode();
        if(res.status===5003003){
          this.isUsable = true;
          this.isCompanyName= true;
        }
      }
    },
    // 保存图形验证码值
    handleGraphic(data) {
      this.registerForm.verCode = data.verCode;
      this.registerForm.verKey = data.verKey;
      this.isUsable = false;
    },
    // 清除协议提示
    handleClearTip(){
      this.$nextTick(() => {
        this.$refs["registerForm"].clearValidate(["protocol"]);
      })
    },
    regisWayFn(){
      this.isCompanyName = false;
      this.registerForm = {
        telephone: "",
        email:"",
        verCode: "",
        smsCode: "",
        verKey: "",
      }
      this.$refs.graphicInput.handleClear()
      this.$refs.noteVaildInput.handleClear()
      this.$refs.noteVaildInput.clearTimer()
      this.$refs.registerForm.clearValidate()
    },
    // 判断当前手机号或者邮箱已加入黑名单
    async verJoinBlacklist(value) {
      const res = await isJoinBlacklist({
        keyword:value
      });
      if (res.status === 200) {
        if (!res.data.exist) {
          this.isCompanyName = false;
        } else {
          this.isCompanyName = true;
        }
      }else{
        this.isCompanyName = false;
      }
    },
  }
};
</script>
<style lang="scss">
.registerSelect{
  width: 100%;
  margin-bottom: 20px;
  .el-input__inner{
    height: 38px!important;
    line-height: 38px!important;
    border-radius: 2px!important;
  }
}
</style>
<style lang="scss" scoped>
.registerContainer {
  padding: 35px 30px;
  .blue {
    color: #337dff;
  }
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }
    .el-input {
      display: inline-block;
      width: 90%;
      ::v-deep input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        caret-color: #999;
        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: #333 !important;
        }
      }
    }
    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.shortFrame {
        border: 0;
      }
    }
  }
  .passBox {
    display: flex;
    // justify-content: space-between;
    // padding-bottom: 19px;
    line-height: 16px;
    p {
      cursor: pointer;
      line-height: 20px;
      color: #333333;
    }
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #333333;
    }
  }
  .goLogin {
    text-align: right;
    span {
      color: #337dff;
      cursor: pointer;
      &:hover{
        color:#0D53CE;
      }
    }
  }
}
</style>
