// 登录
export default [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/agreementContent/:type",
    name: 'agreementContent',
    component: () => import("@/views/agreement/content.vue"),
    hidden: true,
  },
  {
      path: "/privacyPolicy",
      component: () => import("@/views/mobile/privacyPolicy/index.vue"),
      name: "privacyPolicy",
      meta: {
          title: "隐私政策",
      }
  },
  {
      path: "/serverPolicy",
      component: () => import("@/views/mobile/serverPolicy/index.vue"),
      name: "serverPolicy",
      meta: {
          title: "服务协议",
      }
  },
];
