/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/api/user.js
 * @Date: 2022-07-08 15:38:46
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
import request from "@/utils/request";

const { get, post, put, delete: deleteRq } = request({});

// 退出登录
export function logout(data) {
  return deleteRq({
    url: "/logout",
    data,
  });
}
// 获取用户信息
export function getInfo(data) {
  return get({
    url: "/user/common",
    data,
  });
}
// 密码登录
export function login(data) {
  return post({
    url: "/login",
    data,
  });
}
export function refresh(data) {
  return post({
    url: "/token/refresh",
    data,
  });
}
// 修改密码
export function amendPass(data) {
  return put({
    url: "/user/password",
    data,
  });
}
// 忘记密码
export function forgetPass(data) {
  return put({
    url: "/user/password/forget",
    data,
  });
}
// 注册
export function register(data) {
  return post({
    url: "/register",
    data,
  });
}
// 修改手机号
export function amendTel(data) {
  return put({
    url: "/user/telephone",
    data,
  });
}
// 绑定/修改邮箱
export function amendEmail(data) {
  return put({
    url: "/user/email",
    data,
  });
}
// 校验手机号是否注册
export function checkTel(data) {
  return get({
    url: "/user/telephone/isExist",
    data,
  });
}
// 校验邮箱是否注册
export function checkEmailExist(data) {
  return get({
    url: "/user/email/isExist",
    data,
  });
}

// 获取图形验证码（白名单）
export function getPicCodeBlank(data) {
  return get({
    url: "/pic/captcha/blank",
    data,
  });
}
// 校验图形验证码（白名单）
export function checkPicBlank(data) {
  return get({
    url: "/pic/captcha/check/blank",
    data,
  });
}

// 获取短信验证码（白名单）
export function getSmsCodeBlank(data) {
  return get({
    url: "/sms/captcha/blank",
    data,
  });
}
// 校验短信验证码（白名单）
export function checkSmsBlank(data) {
  return get({
    url: "/sms/captcha/check/blank",
    data,
  });
}
// 获取邮箱验证码（白名单）
export function getCaptchaBlank(data) {
  return get({
    url: "/email/captcha/blank",
    data,
  });
}
// 校验邮箱验证码（白名单）
export function checkEmailBlank(data) {
  return get({
    url: "/email/captcha/check/blank",
    data,
  });
}

// 获取图形验证码
export function getPicCode(data) {
  return get({
    url: "/pic/captcha",
    data,
  });
}
// 校验图形验证码
export function checkPic(data) {
  return get({
    url: "/pic/captcha/check",
    data,
  });
}

// 获取短信验证码
export function getSmsCode(data) {
  return get({
    url: "/sms/captcha",
    data,
  });
}
// 校验短信验证码
export function checkSms(data) {
  return get({
    url: "/sms/captcha/check",
    data,
  });
}
// 获取邮箱验证码
export function getCaptcha(data) {
  return get({
    url: "/email/captcha",
    data,
  });
}
// 校验邮箱验证码
export function checkEmail(data) {
  return get({
    url: "/email/captcha/check",
    data,
  });
}

// 回显加密的手机和邮箱
export function userConcat(data) {
  return get({
    url: "/user/concat",
    data,
  });
}
// 查询所有国家
export function getCountry(data) {
  return get({
    url: "/area/getCountry",
    data,
  });
}
// 获取地区
export function getProvince(data) {
  return get({
    url: `/area/getProvinceByCountryId/${data}`,
    data,
  });
}
// 据地区(省份)编号查询所属省份的市区
export function getCity(data) {
  return get({
    url: `/area/getCityByProId/${data}`,
    data,
  });
}

// 根据用户编号查询当前消息该用户是否已读并返回当前未读消息信息
export function getInvitation(userId) {
  return get({
    url: `/organization/invitation/message/${userId}`,
  });
}

// 第三方登录code请求
export function oauthLogin(data) {
  return get({
    url: `/oauth/login`,
    data,
  });
}

// 第三方登录绑定手机号
export function telephone(data) {
  return get({
    url: `/oauth/login/telephone`,
    data,
  });
}
// 校验邮箱是否注册
export function getExist(data) {
  return get({
    url: "/user/email/isExist",
    data,
  });
}
// 获取用户体系
export function getUserType(data) {
  return get({
    url: "/login/type",
    data,
  });
}
// 注册、登录输入的邮箱号或者手机号是否已加入黑名单
export function isJoinBlacklist(data) {
  return get({
    url: "/black/keypoint",
    data,
  });
}
// github登录调用
export function getGithubRender() {
  return get({
    url: "/render",
  });
}
// github登录
export function githubLogin(data) {
  return post({
    url: "/github/login",
    data,
  });
}
// github解绑
export function unbindGit() {
  return deleteRq({
    url: "/user/github",
  });
}
// github绑定
export function bindGit(data) {
  return put({
    url: "/user/github",
    data,
  });
}