<template>
  <div>
    <Authentication v-if="isFirstPage" :mode="mode" @handleModify="handleModify"></Authentication>
    <div v-else class="bindTelContainer">
      <div class="category">
        <ul>
          <li>绑定手机</li>
        </ul>
      </div>
      <p class="boxTip">请输入需绑定的手机号</p>
      <el-form
        ref="bindTelForm"
        :model="bindTelObj"
        :rules="bindTelRules"
        class="formBox bindTelForm"
      >
        <!-- 手机号 -->
        <el-form-item prop="telephone">
          <span class="svg-container">
            <svg-icon icon-class="phone" />
          </span>
          <el-input
            v-model="bindTelObj.telephone"
            placeholder="请输入手机号"
            type="text"
            @change="deleteSpace(bindTelObj.telephone,'telephone')"
          />
        </el-form-item>
        <!-- 短信验证码 -->
        <el-form-item prop="newSmsCode" class="shortFrame" style="margin-bottom:16px">
          <NoteVaild
            :isUsable="isCompanyName"
            :number="bindTelObj.telephone"
            :mode="mode"
            @handleNote="handleNote"
          ></NoteVaild>
          <el-input
            v-show="false"
            v-model="bindTelObj.newSmsCode"
            placeholder="请输入短信验证码"
            type="text"
            @change="deleteSpace(bindTelObj.newSmsCode,'newSmsCode')"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          size="default"
          style="width:100%;margin-top:30px"
          @click.native.prevent="handleVerify"
        >确认绑定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import Authentication from "./authentication";
import { regExpObj } from "@/utils/validate";
import { checkTel, amendTel } from "@/api/user";
export default {
  name: "BindTel",
  components: { NoteVaild, Authentication },
  data() {
    return {
      bindTelObj: {
        telephone: "",
        smsCode: "",
        newSmsCode: "",
        type: ""
      },
      // 当前环节修改手机号
      mode: "TELEPHONE",
      isCompanyName: false, // 名称是否走唯一性校验
      isFirstPage: true, //是否走身份校验
    }
  },
  computed: {
    bindTelRules() {
      // 手机号校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.phoneExp;
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          if (this.isCompanyName) {
            return callback();
          }
          const res = await checkTel({
            telephone: this.bindTelObj.telephone,
          });
          if (res.status === 200) {
            if (!res.data.exist) {
              this.isCompanyName = false;
              callback(new Error("手机号已注册"));
            } else {
              this.isCompanyName = true;
              callback();
            }
          } else {
            this.isCompanyName = false;
            callback(new Error(res.msg));
          }
        }
      };
      return {
        telephone: [
          {
            required: true,
            validator: phoneRule,
            trigger: "blur"
          }
        ],
        newSmsCode: [
          {
            required: true,
            message: "短信验证码不能为空",
            trigger: "blur"
          }
        ],
      };
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleModify(data) {
      this.isFirstPage = false;
      this.bindTelObj.smsCode = data.smsCode;
      this.bindTelObj.type = data.type;
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      if (keyword === "telephone") {
        this.isCompanyName = false;
      }
      this.bindTelObj[keyword] = value.trim();

    },
    // 保存短信验证码值
    handleNote(data) {
      this.bindTelObj.newSmsCode = data
    },
    // 绑定
    handleVerify() {
      this.$refs.bindTelForm.validate(valid => {
        if (valid) {
          this.handleRegist();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleRegist() {
      const res = await amendTel(this.bindTelObj);
      if (res.status === 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.$store.dispatch("user/setAmendNum", this.bindTelObj.telephone);
        this.$emit('handleClose');
      } else if (res.status === 5003003 || res.status === 5003005) {
        this.isFirstPage = true;
      } else {
        res.status === 401 && this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.bindTelContainer {
  padding: 55px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .boxTip {
    text-align: center;
    color: #999;
    padding: 25px 0 8px;
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }
    .el-input {
      display: inline-block;
      width: 90%;

      ::v-deep input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        caret-color: #999;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: #333 !important;
        }
      }
    }
    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.shortFrame {
        border: 0;
      }
    }
  }
}
</style>
