// JOB管理
export default [
    {
        path: "/JOB",
        name: "JOB",
        meta: {
            title: "JOB管理",
            activeMenu: "/JOB",
            unorgShow: true, // 没有组织时也展示
        },
        component: () => import("@/views/jobManagement/index.vue"),
    },
];
