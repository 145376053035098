/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\algorithmManagement.js
 * @Date: 2022-08-03 11:09:55
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 算法管理
export default [
  {
    path: "/resources/algorithms",
    meta: {
      title: "算法管理",
    },
    component: () => import("@/views/algorithmManagement/index.vue"),
    redirect: "/resources/algorithms",
    children: [
      {
        path: "/",
        name: "algorithmManagement",
        meta: {
          title: "算法管理",
        },
        component: () => import("@/views/algorithmManagement/algorithmList"),
      },
      {
        path: "new",
        name: "algorithmNew",
        meta: {
          title: "新建算法",
          activeMenu: "/resources/algorithms",
        },
        component: () => import("@/views/algorithmManagement/algorithmNew"),
      },
      {
        path: ":algorithmId/test/:direction/:evaluateType",
        name: "AlgorithmTest",
        meta: {
          title: "算法调试",
          activeMenu: "/resources/algorithms",
        },
        component: () => import("@/views/algorithmManagement/algorithmTest"),
      },
      {
        path: ":algorithmId",
        name: "algorithmId",
        meta: {
          title: "编辑算法",
          activeMenu: "/resources/algorithms",
        },
        component: () => import("@/views/algorithmManagement/algorithmNew"),
      },
      {
        path: "view/:algorithmId",
        name: "lookAlgorithm",
        meta: {
          title: "查看工算法",
          activeMenu: "/resources/algorithms",
        },
        component: () => import("@/views/algorithmManagement/algorithmNew"),
      },
    ],
  },
];
