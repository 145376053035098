/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\router\module\statisticalAnalysis.js
 * @Date: 2022-08-02 18:48:38
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
export default [
  {
    path: "/resources/statisticalAnalysis",
    component: () => import("@/views/statisticalAnalysis/index.vue"),
    name: "StatisticalAnalysis",
    meta: {
      title: "统计与分析",
    },
  }
];
