<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\views\login\index.vue
 * @Date: 2022-07-08 15:38:46
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div>
    <el-dialog
      v-if="loginPop"
      :visible.sync="loginPop"
      width="708px"
      title
      top="0"
      :destroy-on-close="true"
      :show-close="false"
      custom-class="loginDialog"
    >
      <div class="container">
        <div class="loginLeft">
        </div>
        <div class="content">
          <svg-icon icon-class="loginClose" class="loginClose" @click="handleCloseBox"></svg-icon>
          <!-- 登录 -->
          <LoginPage v-if="category==='1'" ref="loginPage" @handleCut="handleCut" @handleClose="handleCloseBox"></LoginPage>
          <!-- 注册 -->
          <Register v-if="category==='2'" @handleCut="handleCategory"></Register>
          <!-- 忘记密码 -->
          <FindPass v-if="category==='3'" @handleCut="handleCategory"></FindPass>
          <!-- 成功提示 -->
          <SuccessPage v-if="category==='4'" :status="status" @handleCut="handleCut"></SuccessPage>
          <!-- 绑定手机 -->
          <BindTel v-if="category==='5'" @handleClose="handleCloseBox"></BindTel>
          <!-- 绑定邮箱 -->
          <BindEmail v-if="category==='6'" @handleClose="handleCloseBox"></BindEmail>
          <!-- 修改密码 -->
          <AmendPass v-if="category==='7'" @handleCut="handleCategory"></AmendPass>
          <!-- 微信登录 -->
          <WeChat v-if="category==='8'" @handleCut="handleCut" @telLogin="telLogin"></WeChat>
          <!-- 钉钉登录 -->
          <DdLogin v-if="category==='9'" @handleCut="handleCut" @telLogin="telLogin"></DdLogin>
          <!-- 扫码绑定手机号 -->
          <ScanBindTel v-if="category==='11'" @handleCut="handleCut" @handleClose="handleCloseBox"></ScanBindTel>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script> 
// import { phoneRule } from "@/utils/validate";
// import { mobileLoginCode } from "@/api/user";
import { setToken } from "@/utils/auth";
import LoginPage from "./components/loginPage";
import Register from "./components/register";
import FindPass from "./components/findPass";
import SuccessPage from "./components/successPage";
import BindTel from "./components/bindTel";
import BindEmail from "./components/bindEmail";
import AmendPass from "./components/amendPass";
import WeChat from "./components/weChat";
import DdLogin from "./components/ddLogin";
import ScanBindTel from "./components/scanBindTel";

import { mapGetters } from "vuex";
export default {
  name: "LoginHome",
  components: {WeChat, DdLogin, ScanBindTel, LoginPage, Register, FindPass, SuccessPage, BindTel, BindEmail, AmendPass },
  data() {
    // const phoneNumberRule = phoneRule;
    return {
      category: "1", // 显示的类别 1登录，2注册，3找回密码，4注册成功，5绑定手机号，6绑定邮箱，7修改密码
      status: "" // 2注册，3找回密码，7修改密码
    };
  },
  computed: {
    ...mapGetters(["loginPop",])
  },
  methods: {
    // 关闭登录弹窗
    handleCloseBox() {
      this.remove();
      this.$store.dispatch("user/setLoginPop", false);
    },
    // 切换显示登录或注册
    handleCut(type) {
      this.category = type
    },
    // 手机号登录
    telLogin() {
      this.$nextTick(() => {
        this.$refs.loginPage.loginMenu = false
      })
    },
    // 切换成功提示页面为什么成功
    handleCategory(data) {
      this.category = data.type;
      this.status = data.status;
    }
  },
};
</script>

<style lang="scss" scoped>
.loginDialog {
  ::v-deep & {
    top: 50%;
    transform: translateY(-50%);
  }
  .container {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    .loginLeft {
      flex-shrink: 0;
      width: 338px;
      // height: 472px;
      background: url("@/assets/logo/loginPhoto.png") no-repeat center;
      background-size: cover;
    }
    .content {
      width: 60%;
      position: relative;
      height: 482px;
      .loginClose {
        position: absolute;
        top: -2px;
        right: 0;
        font-size: 52px;
        cursor: pointer;
      }
    }
  }
}
</style>
