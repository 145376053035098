/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \release\evaluation-protal\src\router\module\projectManagement.js
 * @Date: 2022-08-08 10:07:30
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 方案管理
export default [
  {
    path: "/resources/schemes",
    name: "projectManagement",
    meta: {
      title: "方案管理",
      activeMenu: "/resources/schemes",
    },
    component: () => import("@/views/projectManagement/index.vue"),
  },
  {
    path: "/resources/schemes/new",
    meta: {
      title: "新建方案",
      activeMenu: "/projectManagement",
    },
    component: () =>
      import("@/views/projectManagement/childPage/creatOrEditPro.vue"),
    name: "CreatPro",
  },
  {
    path: "/resources/schemes/:schemeId",
    meta: {
      title: "编辑方案",
      activeMenu: "/projectManagement",
    },
    component: () =>
      import("@/views/projectManagement/childPage/creatOrEditPro.vue"),
    name: "EditPro",
  },
  {
    path: "/tuRing/:schemeId",
    name: "TuRing",
    meta: {
      title: "图灵详情",
      activeMenu: "/projectManagement",
    },
    component: () => import("@/views/projectManagement/tuRing.vue"),
  },
  {
    path: "/dialogue/:schemeId",
    name: "Dialogue",
    meta: {
      title: "提交详情",
      activeMenu: "/projectManagement",
    },
    component: () => import("@/views/projectManagement/dialogue.vue"),
  },
  {
    path: "/resources/schemes/:schemeId/test/:evaluateWay",
    name: "projectTest",
    meta: {
      title: "方案测试",
    },
    component: () => import("@/views/projectManagement/projectTest.vue"),
  },
];
