/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\caseManagement.js
 * @Date: 2022-08-02 18:48:38
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
export default [
  {
    path: "/resources/clusters",
    component: () => import("@/views/caseManagement/manage.vue"),
    name: "CaseManagement",
    meta: {
      title: "用例管理",
    },
  },
  {
    path: "/resources/clusters/new",
    component: () => import("@/views/caseManagement/create.vue"),
    name: "CreateCase",
    meta: {
      title: "新建用例",
    },
  },
  {
    path: "/resources/clusters/:clusterId",
    component: () => import("@/views/caseManagement/create.vue"),
    name: "EditCase",
    meta: {
      title: "编辑用例",
    },
  },
  {
    path: "/resources/clusters/:clusterId/preview",
    component: () => import("@/views/caseManagement/zipList.vue"),
    name: "CaseZipList",
    meta: {
      title: "用例详情",
    },
  },
  {
    path: "/resources/clusters/:clusterId/:belong",
    component: () => import("@/views/caseManagement/detail.vue"),
    name: "CaseDetail",
    meta: {
      title: "用例文件详情",
    },
  },
  //
];
