/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\settings.js
 * @Date: 2022-07-08 15:40:00
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// 设置
export default [
  {
    path: "/settings/profile",
    component: () => import("@/views/userCenter/data.vue"),
    name: "UserCenter",
    meta: {
      title: "公开资料",
      show: false
    },
  },
  {
    path: "/settings/certificated",
    component: () => import("@/views/userCenter/auth.vue"),
    name: "Auth",
    meta: {
      title: "实名认证",
      show: false
    },
  },
  {
    path: "/settings/account",
    component: () => import("@/views/userCenter/info.vue"),
    name: "AccoutInfo",
    meta: {
      title: "账号信息",
      show: false
    },
  },
  {
    path: "/settings/organizations",
    component: () => import("@/views/userCenter/my.vue"),
    name: "Organization",
    meta: {
      title: "我的组织",
      show: false
    },
  },
  {
    path: "/settings/mirror",
    component: () => import("@/views/userCenter/mirror.vue"),
    name: "Mirror",
    meta: {
      title: "我的镜像",
      show: false
    },
  },
  {
    path: "/settings/mirrorDetail/:id",
    component: () => import("@/views/userCenter/mirrorDetail.vue"),
    name: "MirrorDetail",
    meta: {
      title: "镜像详情",
      show: false
    },
  },
  {
    path: "/settings/organizations/new",
    component: () => import("@/views/userCenter/newly.vue"),
    name: "newlybuild",
    meta: {
      title: "新建组织",
      show: false
    },
  },
  {
    path: "/settings/organizations/:orgId",
    component: () => import("@/views/userCenter/setf.vue"),
    name: "setface",
    meta: {
      title: "信息设置",
      show: false
    },
  },
  {
    path: "/settings/logout",
    component: () => import("@/views/userCenter/logout.vue"),
    name: "Logout",
    meta: {
      title: "注销账号",
      show: false
    },
  },
  {
    path: "/settings/ticket",
    component: () => import("@/views/userCenter/ticket.vue"),
    name: "Ticket",
    meta: {
      title: "问题反馈",
      show: false
    },
  },
  {
    path: "/settings/ticket/new",
    component: () => import("@/views/userCenter/ticketNew.vue"),
    name: "NewTicket",
    meta: {
      title: "提交问题",
      show: false
    },
  },
  {
    path: "/settings/ticket/:id",
    component: () => import("@/views/userCenter/ticketNew.vue"),
    name: "TicketDetail",
    meta: {
      title: "问题详情",
      show: false
    },
  },
  // ],
  // },
];
