// 评测领域
export default [
  {
    path: "/direction",
    meta: {
      title: "评测领域",
      activeMenu: "/direction",
      requiresAuth: true
    },
    component: () => import("@/views/evaluatingField/index.vue"),
    redirect: "/direction",
    children: [
      {
        path: "/",
        meta: {
          title: "评测领域",
        },
        component: () => import("@/views/evaluatingField/field"),
        name: "direction",
      },
      {
        path: ":field",
        meta: {
          title: "AI任务类型",
          activeMenu: "/direction",
        },
        component: () => import("@/views/evaluatingField/fieldList"),
        name: "field",
      },
      {
        path: ":field/:id",
        meta: {
          title: "AI任务类型",
          activeMenu: "/direction",
        },
        component: () => import("@/views/evaluatingField/fieldSecondList"),
        name: "list",
      },
      {
        path: "ai/comprehensive/index",
        meta: {
            title: "综合评测",
            activeMenu: "/direction",
        },
        component: () => import("@/views/evaluatingField/comprehensive"),
        name: "comprehensive",
    },
    ],
  },
];
