<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/components/dt/Table/index.vue
 * @Date: 2022-07-08 10:09:58
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<!-- dt-table -->
<template>
  <el-table
    ref="eleTable"
    :data="data"
    :stripe="stripe"
    :size="size"
    :header-cell-style="headerCellStyle"
    v-bind="$attrs"
    @selection-change="handleSelectionChange"
    @select="select"
    @sort-change="sortChange"
    @cell-mouse-enter="cellMouseEnter"
    @cell-mouse-leave="cellMouseLeave"
  >
    <template slot="empty" v-if="!isEmpty">
      <div class="empty-table" v-if="emptyState">
        <img src="@/assets/emptyLst.png" alt="" style="height: 336px" />
        <p style="top: 70%; transform: translate(-50%, -70%)">暂无数据</p>
      </div>
      <div class="empty-table" v-else>
        <img src="@/assets/emptyLstGray.png" alt="" style="height: 223px" />
        <p style="top: 85%; transform: translate(-50%, -85%)">暂无数据</p>
      </div>
    </template>
    <slot />
  </el-table>
</template>
<script>
export default {
  name: "DtTable",
  props: {
    data: {
      default() {
        return [];
      },
      type: Array,
    },
    stripe: {
      default() {
        return false;
      },
      type: Boolean,
    },
    border: {
      default() {
        return true;
      },
      type: Boolean,
    },
    size: {
      default() {
        return "medium";
      },
      type: String,
    },
    headBg: {
      default() {
        return "#F5F6FA";
      },
      type: String,
    },
    emptyState: {
      default() {
        return true; //因为表格有的是白色底有的是灰色底
      },
      type: Boolean,
    },
    isEmpty: {
      default() {
        return false; //不显示暂无数据
      },
      type: Boolean,
    },
    headerCellStyle: {
      type: Function,
      default: () => {
        return {
          background: '#F5F6FA',
          border: 'none',
        }
      }
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    select(selection, row) {
      this.$emit("select", selection, row);
    },
    sortChange(column) {
      this.$emit("sort-change", column);
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit("cell-mouse-enter", { row, column, cell, event });
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit("cell-mouse-leave", { row, column, cell, event });
    },
  },
};
</script>
<style lang="scss">
.el-table__empty-text {
  line-height: normal !important;
  width: 100%;
}
</style>
<style lang="scss" scoped>
@import "~@/styles/index.scss";
.empty-table {
  text-align: center;
  color: #abb1bf;
  font-size: 16px;
  padding-top: 50px;
  position: relative;
  img {
    width: imgZoomWidth(756) + px;
  }
  p {
    position: absolute;
    left: 50%;
  }
}
</style>
