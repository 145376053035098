<template>
  <el-select
    v-model="value"
    filterable
    remote
    :placeholder="placeholder"
    :remote-method="searchPerson"
    :loading="loading"
    @change="onChange"
    style="width: 100%;"
    :clearable="clearable"
  >
    <el-option
      v-for="item in personList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { filterUserApi } from '@/api/memberManage'

export default {
  name: 'DtSearchUser',
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: '',
      loading: false,
      personList: []
    }
  },
  mounted() {},
  methods: {
    searchPerson(queryString) {
      if (queryString) {
        this.loading = true

        filterUserApi({userName:queryString}).then(res => {
          if (res.status === 200) {
            const list = res.data?res.data.map((item) => {
              return {
                label: `${item.userName}`,
                value: item.userId
              }
            }):[]
            this.personList = list
          }
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.personList = []
      }
    },
    onChange() {
      this.$emit('update:userId', this.value)
    },
    // setValue(value) {
    //   this.value = value
    // },
    // setPersonList(arr) {
    //   this.personList = arr
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>
