<template>
  <div>
    <Authentication v-if="isFirstPage" :mode="mode" @handleModify="handleModify"></Authentication>
    <div v-else class="bindEmailContainer">
      <div class="category">
        <ul>
          <li>绑定邮箱</li>
        </ul>
      </div>
      <p class="boxTip">请输入需绑定的邮箱</p>
      <el-form
        ref="bindEmailForm"
        :model="bindEmailObj"
        :rules="bindEmailRules"
        class="formBox bindEmailForm"
      >
        <!-- 邮箱 -->
        <el-form-item prop="email">
          <span class="svg-container">
            <svg-icon icon-class="email" style="color:#7d7d7d" />
          </span>
          <el-input
            v-model="bindEmailObj.email"
            placeholder="请输入邮箱"
            type="text"
            maxlength="30"
            @change="deleteSpace(bindEmailObj.email,'email')"
          />
        </el-form-item>
        <!-- 短信验证码 -->
        <el-form-item prop="newSmsCode" class="shortFrame" style="margin-bottom:16px">
          <NoteVaild
            :isUsable="isCompanyName"
            :number="bindEmailObj.email"
            :mode="mode"
            @handleNote="handleNote"
          ></NoteVaild>
          <el-input
            v-show="false"
            v-model="bindEmailObj.newSmsCode"
            placeholder="请输入验证码"
            type="text"
            @change="deleteSpace(bindEmailObj.newSmsCode,'newSmsCode')"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          size="default"
          style="width:100%;margin-top:30px"
          @click.native.prevent="handleVerify"
        >确认绑定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import NoteVaild from "@/components/noteVaild/index";
import Authentication from "./authentication";
import { regExpObj } from "@/utils/validate";
import { amendEmail,getExist } from "@/api/user";
export default {
  name: "BindEmail",
  components: { NoteVaild, Authentication },
  data() {
    return {
      bindEmailObj: {
        email: "",
        smsCode: "",
        newSmsCode: "",
        type: ""
      },
      // 图形验证码图像
      mode: "EMAIL",
      isCompanyName: false, // 名称是否走唯一性校验
      isFirstPage: true,//是否走身份校验
    }
  },
  computed: {
    bindEmailRules() {
      // 邮箱校验
      const phoneRule = async (rule, value, callback) => {
        const re = regExpObj.emailExp;
        if (value === "") {
          callback(new Error("邮箱不能为空"));
        } else if (!re.test(value)) {
          callback(new Error("邮箱格式不正确"));
        } else {
          const res = await getExist({email:value});
          if(res.status === 200){
            if(!res.data.exist){
              callback(new Error("邮箱号已注册"));
            }else{
              this.isCompanyName = true;
              callback();
            }
          }
        }
      };
      return {
        email: [
          {
            required: true,
            validator: phoneRule,
            trigger: "blur"
          }
        ],
        newSmsCode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
      };
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleModify(data) {
      this.isFirstPage = false;
      this.bindEmailObj.smsCode = data.smsCode;
      this.bindEmailObj.type = data.type;
    },
    // 去掉首字母空格
    deleteSpace(value, keyword) {
      if (keyword === "email") {
        this.isCompanyName = false;
      }
      this.bindEmailObj[keyword] = value.trim();

    },
    // 保存短信验证码值
    handleNote(data) {
      this.bindEmailObj.newSmsCode = data;
    },
    // 绑定
    handleVerify() {
      this.$refs.bindEmailForm.validate(valid => {
        if (valid) {
          this.handleCheck();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleCheck() {
      const res = await amendEmail(this.bindEmailObj);
      if (res.status === 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.$store.dispatch("user/setAmendNum", this.bindEmailObj.email);
        this.$emit('handleClose');
      } else if (res.status === 5003003 || res.status === 5003005) {
        this.isFirstPage = true;
      } else {
        res.status === 401 && this.$message({
          message: res.msg,
          type: 'warning'
        });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.bindEmailContainer {
  padding: 55px 30px;
  .category {
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        line-height: 26px;
        font-size: 18px;
        color: #111111;
        cursor: pointer;
        padding-bottom: 4px;
        &.active {
          color: #337dff;
          border-bottom: 3px solid #337dff;
        }
      }
    }
  }
  .boxTip {
    text-align: center;
    color: #999;
    padding: 25px 0 8px;
  }
  .formBox {
    margin-top: 20px;
    .svg-container {
      padding-left: 10px;
    }
    .el-input {
      display: inline-block;
      width: 90%;

      ::v-deep input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        caret-color: #999;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px #fff inset !important;
          -webkit-text-fill-color: #333 !important;
        }
      }
    }
    .el-form-item {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      color: #454545;
      margin-bottom: 20px;
      ::v-deep .el-form-item__content {
        line-height: 38px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.shortFrame {
        border: 0;
      }
    }
  }
}
</style>
