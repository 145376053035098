/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\permission.js
 * @Date: 2022-07-08 15:38:46
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
import store from "@/store";
import login from "@/views/login/login";
NProgress.configure({ showSpinner: false }); // NProgress Configuration
import { MessageBox } from "element-ui";

const whiteList = [
  "/",
  "/login",
  "/direction",
  "/cluster",
  "/explore",
  "/MSR",
  "/VMR",
  "/ICPR",
  "/INTERSPEECH",
  "/opensource",
  "/Journalism",
  "/news",
  "/standard",
  "/case",
  "/knowledge/standards",
  "/knowledge",
  "/user-manual",
  "/api-docs"
]; // no redirect whitelist
// const whiteList = []; // no redirect whitelist

const whiteNameList = ["contribution", "account", "news", "standards", "cases", "agreementContent", "IssueDetails","privacyPolicy","serverPolicy"];

router.beforeEach(async (to, from, next) => {
  // start progress bar
  // NProgress.start();
  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done(); // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      const view = "/resources/clusters/new";
      if (from.path === view && store.getters.uploadStatus) {
        MessageBox.confirm(
          "离开本页面将自动中断正在上传中的文件，是否确认离开？",
          {
            type: "warning",
          }
        )
          .then(async () => {
            store.dispatch("upload/resetUpload");
            NProgress.done();
            next();
            store.dispatch("user/getInfo");
          })
          .catch((err) => {
            console.log(err);
            NProgress.done();
          });
        return;
      }
      NProgress.done();
      next();
      store.dispatch("user/getInfo");
    }
  } else {
    /* has no token*/
    if (
      whiteList.indexOf(to.path) !== -1 ||
      to.matched.some((record) => record.meta.requiresAuth) ||
      whiteNameList.indexOf(to.name) != -1
    ) {
      next();// in the free login whitelist, go directly
    } else {
      store.dispatch("user/setLoginPop", true);
      login.install({ category: "1" });
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  // 页面刷新滚动条消失又出现
  document
    .querySelector("body")
    .setAttribute("style", "overflow: auto !important;");
  window.scroll(0, 0)
  NProgress.done();
});
