/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: /evaluation-protal/src/utils/crypto.js
 * @Date: 2022-10-26 14:23:55
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */
// import Vue from "vue";
import CryptoJS from "crypto-js";
//加密
export function encrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
//解密
export function decrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
  var key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
