<!-- dt-dialog custom-class:自定义类名 multipleClass（标题左对齐，按钮居中） -->
<!-- 基础与提示弹窗用this.$confirm('内容', '标题', {
          customClass: "baseAlert ",基础信息弹窗必传类名 警告类为 baseAlert warning ，错误类baseAlert error
          其余参数跟elementUI上一样
      })
-->
<template>
  <el-dialog
    :close-on-click-modal="closeOnClickModal"
    :visible.sync="visibleDia"
    :destroy-on-close="destroyOnClose"
    :width="width"
    :append-to-body="appendToBody"
    :before-close="handleDialogClose"
    :custom-class="customClass + ' dt-dialog'"
    :title="title"
    :top="top"
    :center="center"
    v-bind="$attrs"
  >
    <slot />
    <span v-if="cancelBtnText || confirmBtnText" slot="footer" class="dialog-footer">
      <el-button
        v-show="cancelBtnText"
        :disabled="cancelDis"
        type="text"
        size="medium"
        @click="cancelBtnHandler"
        style="margin-right: 25px"
      >{{ cancelBtnText }}</el-button>
      <el-button
        v-show="confirmBtnText"
        type="primary"
        size="medium"
        :disabled="confirmDis"
        @click="confirmBtnHandler"
      >{{ confirmBtnText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  // inject: ["reload"],
  name: "DtDialog",
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 是否居中
    center: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 弹窗高度
    width: {
      type: String,
      default: () => {
        return "30%";
      },
    },
    // 弹窗距上高度
    top: {
      type: String,
      default: () => {
        return "50vh";
      },
    },
    // 弹窗标题
    title: {
      type: String,
      default: () => {
        return "提示";
      },
    },
    // 关闭时销毁文件
    destroyOnClose: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 自定义类名
    customClass: {
      type: String,
      default: () => {
        return "customClass";
      },
    },
    // Dialog 自身是否插入至 body 元素上。
    appendToBody: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 是否可以通过点击modal关闭弹窗
    closeOnClickModal: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    confirmBtnText: {
      type: String,
      default: () => {
        return "";
      },
    },
    cancelBtnText: {
      type: String,
      default: () => {
        return "";
      },
    },
    confirmDis: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    cancelDis: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    defaultCancel: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    visibleDia: {
      handler(val) {
        document.body.removeAttribute("style");
        if (val) {
          document
            .querySelector("body")
            .setAttribute("style", "overflow:hidden!important");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    visibleDia: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    // 确认按钮事件
    confirmBtnHandler() {
      // this.visibleDia = false;
      this.$emit("confirm", false);
    },
    // 取消按钮事件
    cancelBtnHandler() {
      // this.visibleDia = false;
      this.$emit("cancel", false);
    },
    // 关闭前操作
    handleDialogClose() {
      document
        .querySelector("body")
        .setAttribute("style", "overflow:auto!important");
      if (this.defaultCancel === "defaultCancel") {
        this.$emit("cancel", false);
        // this.reload();
      } else {
        this.$emit("update:visible", false);
      }
    },
  },
};
</script>
<style lang="scss">
.dt-dialog {
  position: absolute;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}
.offTopDis {
  transform: translateY(0) !important;
}
.el-dialog__header {
  // border-bottom: 1px solid #dfe6ec;
  // padding-bottom: 15px;
  padding: 32px 34px 10px;
}
.el-dialog__headerbtn {
  top: 32px;
  right: 32px;
}
.el-dialog__body {
  padding: 10px 32px;
}
.el-button--success {
  background-color: #009688;
  border-color: #009688;
}
.el-button--success:hover,
.el-button--success:focus {
  background: #09a898;
  border-color: #09a898;
}
.el-dialog__footer {
  padding: 16px 32px 32px;
}
.multipleClass {
  .el-dialog__footer {
    text-align: center;
  }
}
.loginDialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
