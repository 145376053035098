<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal 评测系统\evaluation-protal\src\components\graphicVaild\index.vue
 * @Date: 2022-07-14 17:43:49
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="graphicBox">
    <el-input
      v-model="verCode"
      :size="size"
      placeholder="请输入图形验证码"
      type="text"
      @input="handleInput"
    />
    <div class="codeImg">
      <img :src="graphicObj.verCode" @click="handleGetCode" alt=""/>
    </div>
  </div>
</template>

<script>
import { getPicCode, getPicCodeBlank } from "@/api/user";
export default {
  name: "GraphicVaild",
  props: {
    // 获取图形验证码场景
    mode: {
      type: String,
      default() {
        return "LOGIN";
      },
    },
    size: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      verCode: "",
      graphicObj: {}
    }
  },
  created() {
    this.handleGetCode();
  },
  methods: {
    // 获取图像验证码
    async handleGetCode() {
      let getCodeUrl = null;
      if (this.mode === "LOGIN" || this.mode === "REGISTER" || this.mode === "FORGETPASS") {
        getCodeUrl = getPicCodeBlank
      } else {
        getCodeUrl = getPicCode
      }
      const res = await getCodeUrl({ mode: this.mode })
      if (res.status === 200) {
        this.graphicObj = res.data
        this.handleInput()
      }
    },
    // 传递图像值
    handleInput() {
      const graphicObj = {
        verCode: this.verCode,
        verKey: this.graphicObj.verKey
      }
      this.$emit("handleGraphic", graphicObj)
    },
    handleClear() {
      this.verCode = ""
    }
  },
};
</script>

<style lang="scss" scoped>
.graphicBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-input {
    display: inline-block;
    width: 200px;
    border: 1px solid #ddd;
    ::v-deep input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 0 10px;
      color: #333;
      caret-color: #999;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: #333 !important;
      }
    }
  }
  .codeImg {
    border: 1px solid #ddd;
    width: 100px;
    height: 40px;
    line-height: 38px;
    cursor: pointer;
    padding-top: 3px;
    img {
      width: 100%;
    }
  }
}
</style>
