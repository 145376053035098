<!--
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\components\noteVaild\index.vue
 * @Date: 2022-07-14 17:43:49
 * Copyright 2022 鹏城实验室 All rights reserved.     注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
-->
<template>
  <div class="noteBox">
    <el-input v-model="smsCode" placeholder="请输入验证码" type="text" @input="handleInput" />
    <a
      href="javascript:;"
      :class="['getCode',{'active':isUsable&&!isDisabled}]"
      @click="handleGetCode"
    >{{text}}</a>
  </div>
</template>

<script>
import { getSmsCode, getCaptcha, getSmsCodeBlank, getCaptchaBlank, } from "@/api/user";
export default {
  name: "NoteVaild",
  props: {
    // 获取图形验证码场景
    mode: {
      type: String,
      default() {
        return "LOGIN";
      },
    },
    isUsable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    number: {
      type: String,
      default() {
        return "";
      },
    },
    // 发验证码方式1手机，2邮箱
    type: {
      type: String,
      default() {
        return "1";
      },
    },
  },
  data() {
    return {
      smsCode: "",
      text: "获取验证码",
      isDisabled: false,
      second: 120,
      timer: null
    }
  },
  created() {
  },
  methods: {
    // 获取验证码
    async handleGetCode() {
      if (this.isDisabled || !this.isUsable) {
        return
      }
      let isBlank = false;
      // 登录注册，忘记密码获取验证码接口走白名单
      if (this.mode === "LOGIN" || this.mode === "REGISTER" || this.mode === "FORGETPASS" || this.mode === "OAUTHLOGIN") {
        isBlank = true
      }
      let handleUrl = isBlank ? getSmsCodeBlank : getSmsCode;
      let params = { mode: this.mode, telephone: this.number };
      if (this.number.indexOf("@") >= 0 || this.type === "2") {
        handleUrl = isBlank ? getCaptchaBlank : getCaptcha;
        params = { mode: this.mode, email: this.number }
      }

      // getCaptcha
      const res = await handleUrl(params)
      if (res.status === 200) {
        this.isDisabled = true;
        this.second = 120;
        this.timer = setInterval(() => {
          this.text = this.second + "秒";
          this.second--;
          if (this.second === 0) {
            this.text = "获取验证码";
            this.isDisabled = false;
            clearInterval(this.timer);
          }
        }, 1000)
        // this.graphicObj = res.data
      }
    },
    // 传递短信验证码
    handleInput() {
      this.$emit("handleNote", this.smsCode)
    },
    handleClear() {
      this.smsCode = ""
    },
    // 针对注册切换不同的注册方式  需要清空验证码
    clearTimer(){
      clearInterval(this.timer);
      this.timer =null
      this.text = "获取验证码";
      this.isDisabled = false;
      this.second= 120
    }
  },
};
</script>

<style lang="scss" scoped>
.noteBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-input {
    display: inline-block;
    width: 200px;
    border: 1px solid #ddd;
    ::v-deep input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 0 10px;
      color: #333;
      caret-color: #999;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: #333 !important;
      }
    }
  }
  .getCode {
    width: 100px;
    height: 40px;
    border-radius: 2px;
    background: #f4f4f5;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    color: #999999;
    margin-left: 10px;
    text-align: center;
    flex-shrink: 0;
  }
  .active {
    background: #337dff;
    border: 1px solid #337dff;
    color: #fff;
  }
}
</style>
