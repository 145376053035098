// 任务管理
export default [
    {
        path: "/tasks",
        name: "tasks",
        meta: {
            title: "任务管理",
            activeMenu: "/tasks",
            unorgShow: true, // 没有组织时也展示
        },
        component: () => import("@/views/taskManagement/index.vue"),
    },
];
