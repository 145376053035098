/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \evaluation-protal\src\router\module\memberManage.js
 * @Date: 2022-08-02 14:00:48
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */

export default [
  {
      path: "/organizations/members",
      name: "memberManage",
      meta: {
          title: "成员管理",
          activeMenu: "/organizations/members",
      },
      component: () => import("@/views/memberManage/index.vue"),
  },
  {
    path: "/organizations/applyVoucherPage",
    name: "applyVoucherPage",
    meta: {
        title: "应用凭证",
        activeMenu: "/organizations/applyVoucherPage",
    },
    component: () => import("@/views/applyVoucherPage/index.vue"),
},
];
