/*
 * @项目名称: AI技术评测基准系统
 * @FilePath: \release\evaluation-protal\src\router\module\modelManage.js
 * @Date: 2022-08-02 14:05:05
 * Copyright 2022 鹏城实验室 All rights reserved. 注意：本内容仅限于鹏城实验室内部传阅，禁止外泄以及用于其他的商业目的
 */

export default [
  {
    path: "/resources/models",
    name: "modelManage",
    meta: {
      title: "模型管理",
      activeMenu: "/resources/models",
    },
    component: () => import("@/views/modelManage/index.vue"),
  },
  {
    path: "/resources/models/new",
    name: "newModel",
    meta: {
      title: "新建模型",
    },
    component: () => import("@/views/modelManage/newModel.vue"),
  },
  {
    path: "/resources/models/:modelId",
    name: "editModel",
    meta: {
      title: "编辑模型",
    },
    component: () => import("@/views/modelManage/newModel.vue"),
  },
  {
    path: "/resources/models/view/:modelId",
    name: "lookModel",
    meta: {
      title: "查看模型",
    },
    component: () => import("@/views/modelManage/newModel.vue"),
  },
  {
    path: "/resources/models/:modelId/versions/new",
    name: "newVersion",
    meta: {
      title: "新建版本",
    },
    component: () => import("@/views/modelManage/newVersion.vue"),
  },
  {
    path: "/resources/models/:modelId/versions/:versionId",
    name: "editVersion",
    meta: {
      title: "编辑版本",
    },
    component: () => import("@/views/modelManage/newVersion.vue"),
  },
  {
    path: "/resources/models/:modelId/versions/view/:versionId",
    name: "viewVersion",
    meta: {
      title: "查看版本",
    },
    component: () => import("@/views/modelManage/newVersion.vue"),
  },
  {
    path: "/resources/models/:modelId/versions",
    name: "versionList",
    meta: {
      title: "版本列表",
    },
    component: () => import("@/views/modelManage/versionList.vue"),
  },
  {
    path: "/resources/models/:modelId/:id/modelVisualized",
    name: "modelVisualized",
    meta: {
      title: "模型可视化",
    },
    component: () => import("@/views/modelManage/modelVisualized.vue"),
  },
  {
    path: "/resources/models/:modelId/versions/:versionId/test",
    name: "modelTest",
    meta: {
      title: "模型测试",
    },
    component: () => import("@/views/modelManage/modelTest.vue"),
  },
  {
    path: "/resources/models/:modelId/development",
    name: "modelDevelop",
    meta: {
      title: "模型开发",
    },
    component: () => import("@/views/modelManage/modelDev.vue"),
  },
];
